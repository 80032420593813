export class AccountInfo {
    public firstName: string;
    public lastName: string;
    public email: string;
    public companyName: string;
    public contactAddress: Address;

    constructor(){
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.companyName = '';
        this.contactAddress = new Address();
    }

    public create(paymentDetail){
        this.firstName = paymentDetail.cardFirstName;
        this.lastName = paymentDetail.cardLastName;
        this.email = paymentDetail.email;
        this.companyName = '';
        this.contactAddress = new Address();
        this.contactAddress.create(paymentDetail.ccAddress);
    }
}

export class Address {
    public addressLine1: string;
    public addressLine2: string;
    public city: string;
    public stateOrProvince: string;
    public postalCode: string;
    public phone: string;
    public countryCode: string;

    constructor(){
        this.addressLine1 = '';
        this.addressLine2 = '';
        this.city = '';
        this.stateOrProvince = '';
        this.postalCode = '';
        this.phone = '';
        this.countryCode = '';
    }

    create(address){
        this.addressLine1 = address.street1;
        this.addressLine2 = address.street2;
        this.city = address.city;
        this.stateOrProvince = address.stateOrProvince;
        this.postalCode = address.zipCode;
        this.countryCode = address.country ? address.country : "US";
        this.phone = "9999999999";
    }
}

export const stateList = ["AK", "AL", "AR", "AS", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "GU", "HI", "IA", "ID",
    "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MH", "MI", "MN", "MO", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY",
    "OH", "OK", "OR", "PA", "PR", "PW", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VI", "VT", "WA", "WI", "WV", "WY"];