import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FraudAlertComponent } from './fraud-alert/fraud-alert.component';

import { CreateFooterComponent } from "./create-footer/create-footer.component";
import {SwitchAccountHeaderComponent} from "@shared/switch-account-header/switch-account-header.component";
import { FormControlValueDirective } from './form-control-value/form-control-value.directive';
import { CanNotProcessRequestComponent } from './error/can-not-process-request/can-not-process-request.component';
import { TrademarkNoticesComponent } from './trademark-notices/trademark-notices.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ContactSupportTeamComponent } from "./error/contact-support-team/contact-support-team.component";
import { AchTimeTableModal } from './modals/ach-time-table/ach-time-table-modal.component';
import { BrowserSupportModal } from './modals/browser-support/browser-support-modal.component';
import { AsteriskToSymbolSpacerPipe } from './pipes/asterisk-to-symbol-spacer.pipe';
import { LastFourDigitPipe } from './pipes/last-four-digit.pipe';
import { RefillPostageModal } from './modals/refill-postage/refill-postage-modal.component';
import { PbdsHeaderShadowModule } from 'pb-design-system/header-shadow';
import { MatRippleModule } from '@angular/material/core';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { DataNotFoundComponent } from './error/data-not-found/data-not-found.component';
import { HttpLoaderFactory } from 'app/app.module';

@NgModule({
    imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, NgbModule, ToastrModule.forRoot(), PbdsHeaderShadowModule,
        MatRippleModule, HttpClientModule, TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })],
    declarations: [
        HeaderComponent,
        FooterComponent,
        PageNotFoundComponent,
        FraudAlertComponent,
        CreateFooterComponent,
        SwitchAccountHeaderComponent,
        FormControlValueDirective,
        CanNotProcessRequestComponent,
        TrademarkNoticesComponent,
        ContactUsComponent,
        ContactSupportTeamComponent,
        AchTimeTableModal,
        BrowserSupportModal,
        AsteriskToSymbolSpacerPipe,
        LastFourDigitPipe,
        RefillPostageModal,
        LanguageSelectorComponent,
        DataNotFoundComponent
    ],
    exports: [HeaderComponent, FooterComponent, PageNotFoundComponent, FraudAlertComponent, CreateFooterComponent, SwitchAccountHeaderComponent,
        FormControlValueDirective, CanNotProcessRequestComponent, ContactSupportTeamComponent, AchTimeTableModal, BrowserSupportModal, AsteriskToSymbolSpacerPipe, LastFourDigitPipe, RefillPostageModal, PbdsHeaderShadowModule,
        MatRippleModule, LanguageSelectorComponent, TranslateModule, DataNotFoundComponent],
    providers: []
})
export class SharedModule {

}
