import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "./shared/page-not-found/page-not-found.component";
import { RoutesInterceptor } from "./helpers/routes.interceptor";
import { LoginComponent } from "./auth/login/login.component";
import { LogoutComponent } from "./auth/logout/logout.component";
import { HeaderComponent } from "./shared/header/header.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { LoginErrorComponent } from "./auth/login-error/login-error.component";
import { CreateFooterComponent } from "./shared/create-footer/create-footer.component";
import { ParcelProtectionModule } from "./parcel-protection/parcel-protection.module";
import { CanNotProcessRequestComponent } from "./shared/error/can-not-process-request/can-not-process-request.component";
import { ContactSupportTeamComponent } from "./shared/error/contact-support-team/contact-support-team.component";

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'home', redirectTo: 'create' },
    {
        path: 'create',
        loadChildren: () => import('./create-merchant/create-merchant.module').then(m => m.CreateMerchantModule)
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'switchAccount',
        loadChildren: () => import('./switch-account/switch-account.module').then( m => m.SwitchAccountModule),
        canActivate:[RoutesInterceptor]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/main-dashboard/main-dashboard.module').then(m => m.MainDashboardModule),
        canActivate: [RoutesInterceptor]
    },
    {
        path: 'online-support',
        loadChildren: () => import('./online-support/online-support.module').then(m => m.OnlineSupportModule),
        canActivate: [RoutesInterceptor]
    },
    {
        path: 'merchantPortalFAQ',
        loadChildren: () => import('./online-support/online-support.module').then(m => m.OnlineSupportModule)
    },
    { path: 'linkBankAccountTerms', redirectTo: 'online-support/bankAccountTnC' },
    {
        path: 'transaction',
        loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule),
        canActivate: [RoutesInterceptor]
    },
    {
        path: 'billing',
        loadChildren: () => import('./billing-payments/billing-payments.module').then(m => m.BillingPaymentsModule),
        canActivate: [RoutesInterceptor]
    },
    {
        path: 'transaction/parcelprotection',
        loadChildren: () => import('./parcel-protection/parcel-protection.module').then(m => m.ParcelProtectionModule),
        canActivate: [RoutesInterceptor]
    },
    {
        path: 'accountinformation',
        loadChildren: () => import('./account-information/account-information.module').then(m => m.AccountInformationModule),
        canActivate: [RoutesInterceptor]
    },
    {
        path: 'creditCalculator',
        loadChildren: () => import('./credit-calculator/credit-calculator.module').then(m => m.CreditCalculatorModule)
    },
    {
        path: 'carrier-setup',
        loadChildren: () => import('./carrier-setup/carrier-setup.module').then(m => m.CarrierSetupModule),
        canActivate: [RoutesInterceptor]
    },
    {
        path: 'shipping-carriers',
        loadChildren: () => import('./shipping-carriers/shipping-carriers.module').then(m => m.ShippingCarriersModule),
        canActivate: [RoutesInterceptor]
    },
    {
        path: 'fdr',
        loadChildren: () => import('./fdr/fdr.module').then(m => m.FdrModule),
        canActivate: [RoutesInterceptor]
    },
    {
        path: 'ai-reports',
        loadChildren: () => import('./ai-reports/ai-reports.module').then(m => m.AiReportsModule),
        canActivate: [RoutesInterceptor]
    },
    {
        path: 'canNotProcessRequest',
        children: [
            {
                path: '',
                outlet: 'header',
                component: HeaderComponent
            },
            {
                path: '',
                component: CanNotProcessRequestComponent
            },
            {
                path: '',
                outlet: 'footer',
                component: FooterComponent
            }
        ]
    },
    {
        path: 'contactSupportTeam',
        children: [
            {
                path: '',
                outlet: 'header',
                component: HeaderComponent
            },
            {
                path: '',
                component: ContactSupportTeamComponent
            },
            {
                path: '',
                outlet: 'footer',
                component: FooterComponent
            }
        ]
    },
    {
        path: 'loginError',
        children: [
            {
                path: '',
                component: LoginErrorComponent
            },
            {
                path: '',
                outlet: 'footer',
                component: CreateFooterComponent
            }
        ]
    },
    {
        path: '**',
        children: [
            {
                path: '',
                outlet: 'header',
                component: HeaderComponent
            },
            {
                path: '',
                component: PageNotFoundComponent
            },
            {
                path: '',
                outlet: 'footer',
                component: FooterComponent
            }
        ]
    }
];

export const AppRoutingModule = RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' });
