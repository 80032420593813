<header class="bg-brand-header fixed-top">

    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <nav class="navbar  navbar-expand-lg navbar-light">
                    <button class="navbar-toggler p-0" type="button" data-bs-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <a class="navbar-brand" routerLink="/dashboard" id="header-merchant-portal">
                        Merchant Portal <ng-container *ngIf="!hasMultipleAccounts">l {{this.developerInfo}}</ng-container>
                    </a>
                    <div *ngIf="userName" class="collapse navbar-collapse" id="navbarSupportedContent">

                        <ul class="navbar-nav mr-auto">
                            <li class="nav-item divider-vertical d-none d-lg-block" *ngIf="hasMultipleAccounts"></li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/dashboard" id="header-nav-home">Home</a>
                            </li>
                            <li class="dropdown">
                                <a class="nav-link" routerLink="/transaction" *ngIf="!isParcelProtection"
                                    id="header-nav-transactions">Transactions</a>
                                <ng-container *ngIf="isParcelProtection">
                                    <a class="dropdown-toggle nav-link btn cursor-pointer" data-bs-toggle="dropdown"
                                        role="button" aria-haspopup="true" aria-expanded="false"
                                        id="header-nav-menu-transactions">Transactions</a>
                                    <div class="dropdown-menu pb-animate-menu" role="menu">
                                        <a class="dropdown-item" routerLink="/transaction"
                                            id="header-nav-all-transactions">All Transactions</a>
                                        <a class="dropdown-item" routerLink="/transaction/parcelprotection"
                                            id="header-nav-parcel-transactions">Transactions
                                            with Complete<br>Parcel Protection</a>
                                    </div>
                                </ng-container>
                            </li>
                            <li class="nav-item" *ngIf="isMultiCarrierEntitled">
                                <a class="nav-link" routerLink="/shipping-carriers" id="header-nav-carriers">Shipping
                                    Carriers</a>
                            </li>
                            <li class="nav-item" *ngIf="isAiReportsEnabled">
                                <a class="nav-link" routerLink="/ai-reports" id="header-nav-carriers">AI Reports</a>
                            </li>
                            <li class="nav-item" *ngIf="isDefaultMerchant">
                                <a class="nav-link" href="{{devPortalUrl}}" id="header-dev-access"
                                    target="_blank" rel="noopener noreferrer">Developer Access</a>
                            </li>
                        </ul>

                        <ul class="navbar-nav navbar-right">
                            <ng-container *ngIf="hasMultipleAccounts">
                                <app-switch-account-header> </app-switch-account-header>
                            </ng-container>
                            <!-- Support menu -->
                            <li class="nav-item dropdown menu-help">
                                <a href="" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                                    aria-labelledby="help-offscreen" aria-expanded="false" aria-haspopup="true"
                                    id="header-nav-support">
                                    <i class="pbi-icon-outline pbi-help-circle d-none d-lg-inline"></i>

                                    <span class="d-lg-none" id="help-offscreen">
                                        <i class="pbi-icon-mini pbi-help-circle"></i>&nbsp;Online Support
                                    </span>
                                </a>

                                <div class="dropdown-menu" role="menu">
                                    <a class="dropdown-item" routerLink="/online-support"
                                        id="header-nav-online-support">
                                        <i class="pbi-icon-mini pbi-help-circle"></i> Online Support
                                    </a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="mailto:PaymentsAPISupport@pb.com"
                                        id="header-nav-email">
                                        <i class="pbi-icon-mini pbi-mail"></i> Email</a>
                                    <!-- <a class="dropdown-item" href="tel:855-393-8874" id="header-nav-phone">
                                        <i class="pbi-icon-mini pbi-phone"></i> 855-393-8874</a> -->
                                </div>
                            </li>
                            <!-- Settings menu -->
                            <li class="nav-item dropdown menu-help" *ngIf="isMFAEnabled();">
                                <a href="" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                                    aria-labelledby="help-offscreen" aria-expanded="false" aria-haspopup="true"
                                    id="header-settings-menu">
                                    <i class="pbi-icon-outline pbi-settings d-none d-lg-inline"
                                        id="header-settings-menu-icon"></i>
                                </a>
                                <div class="dropdown-menu" role="menu">
                                    <a class="dropdown-item" routerLink="/auth/configure-mfa" id="header-mfa">
                                        <i class="pbi-icon-mini pbi-lock" id="header-mfa-icon"></i>
                                        Multi-factor Authentication
                                    </a>
                                </div>
                            </li>

                            <!-- User menu -->
                            <li class="nav-item dropdown menu-user">
                                <a href="" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                                    aria-labelledby="username-offscreen" aria-expanded="false" id="header-user-menu">

                                    <div class="d-none d-lg-block header-user-lockup">
                                        <div class="header-username" id="menu-user-name-lockup">{{this.userName}}</div>
                                    </div>

                                    <span class="d-lg-none" id="username-offscreen">
                                        <i class="pbi-icon-mini pbi-user"></i>&nbsp;{{this.userName}}
                                    </span>
                                </a>

                                <div class="dropdown-menu" role="menu">
                                    <a *ngIf="!this.setUpPending && !hasPostPayPaymentMethod" class="dropdown-item" routerLink="/billing"
                                        id="menu-user-postage-and-payments">Postage and Payments</a>
                                    <a *ngIf="this.setUpPending" class="dropdown-item" (click)="setUpWarning()"
                                        id="menu-user-postage-and-payments-warning">Postage and Payments</a>
                                    <a class="dropdown-item" routerLink="/accountinformation"
                                        id="menu-user-account-information">Account Information</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" routerLink="/logout" id="menu-user-sign-out">Sign Out</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>

</header>