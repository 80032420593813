import {AccountInfo} from "./AccountInfo";

export class MerchantRegistrationDetails {

    public developerId : string;
    public paymentMethod: string;
    public fraudStatus: string;
    public merchantStatus: string;
    public accountInfo: AccountInfo;
    public errorMessage: string;
    public referral_code: string;
    public selectedMFA: boolean;
    constructor() {
        this.clear();
    }

    clear(){
        this.developerId = null;
        this.paymentMethod = null;
        this.accountInfo = new AccountInfo();
        this.errorMessage = null;
        this.fraudStatus = null;
        this.merchantStatus = null;
        this.referral_code = null;
    }
}
