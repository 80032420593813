<div class="signin-wrapper mt-3 mb-4" role="main">

    <a class="signin-logo d-block mt-2 mb-4" href="http://www.pb.com" target="_blank" rel="noopener noreferrer"></a>

    <div class="signin-box">

        <h2 class="mt-0">Your login attempt failed.</h2>

        <p>{{errorMessage}}</p>

        <!--<p>Please retry with valid credentials/token.</p>-->

        <form *ngIf="!isIframe" name="errorForm" class="mt-2 mb-4" (ngSubmit)="redirectToLogin()">
            <br>
            <button class="btn btn-page-primary btn-block btn-lg" type="submit" >
                Login
            </button>
        </form>

        <p>Need help? Contact <a href="mailto:{{supportEmail}}">Support Team</a> 
            <!-- or call at {{supportPhone}} -->
        </p>

    </div>
</div>