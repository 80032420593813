import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AiReportsService {

  constructor(private http: HttpClient) { }


  generateRealTimeReport(reqBody): Observable<any> {
    let url = '/api/v1/generate/realtimereport';
    return this.http.post(url, reqBody);
  }

  isAiReportsEnabled(): Observable<any> {
    let url = '/api/v1/isAiReportsEnabled';
    return this.http.get(url);
  }

  submitFeedback(reqBody): Observable<any> {
    let url = '/api/v1/submitFeedback';
    return this.http.post(url, reqBody);
  }
}
