import { Component, OnInit } from '@angular/core';
import { ClientLogger } from '@services/client.logger.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isNullOrUndefined } from "util";
import { ToastrService } from 'ngx-toastr';
import { Constants } from "@utils/constants";
import { Router } from '@angular/router';
import { MultifactorAuthService } from '@services/multifactor-auth.service';



@Component({
  selector: 'app-configure-multifactor-auth',
  templateUrl: './configure-multifactor-auth.component.html'
})
export class ConfigureMultifactorAuthComponent implements OnInit {

  public isMFAEnabled: boolean;
  public configureAction: string;
  public configureSuccessHeading: string;
  public configureSuccessMsg: string;
  
  // constants declerations
  public readonly ENABLE_MFA = 'enable';
  public readonly DISABLE_MFA = 'disable';
  public readonly RESET_MFA = 'reset';
  public readonly LOGOUT_MFA = 'logout';

  constructor(private multifactorAuthService: MultifactorAuthService, private clientLogger: ClientLogger,
    private modalService: NgbModal, private toastr: ToastrService, private router: Router) { }

  ngOnInit() {
    this.getMerchantMFAConfiguration();
  }

  /**
   * This function is used to get the MFA configuration for existing merchant. 
   * Based on the MFA allready enabled, we hide and show the MFA enable button. 
   */
  getMerchantMFAConfiguration() {
    this.multifactorAuthService.getUserMFAGroupMembership().subscribe(response => {
      if (response && response.success && response.error === false) {
        this.isMFAEnabled = true;
      } else {
        this.isMFAEnabled = false;
      }
    }, error => {
      this.toastr.error(Constants.GENERIC_ERROR);
      this.clientLogger.log("Error occured while  get merchant MFA configuration ", error);
    });
  }

  /**
   * This function is used to open a model for caputuring the user's confirmation for MFA enablement.
   * 
   * @param event 
   * @param action 
   * @param modal 
   * @param optionSet 
   */
  openConfigureMFAConfirmModal(event, action, modal, optionSet?) {
    this.configureAction = action;
    event.preventDefault();
    this.modalService.open(modal, optionSet);
  }

  /**
   * This function is used to capture the user choice while performing MFA related activity.
   * 
   * @param event 
   * @param actionName 
   * @param modal 
   * @param optionSet 
   */
  configureMFA(event, actionName, modal, optionSet?) {
    this.clientLogger.log("the user actionName is ", actionName);
    if (!isNullOrUndefined(actionName) && actionName == this.ENABLE_MFA) {
      this.enableMFA(event, modal, optionSet);
    } else if (!isNullOrUndefined(actionName) && actionName == this.RESET_MFA) {
      this.resetMFA(event, modal, optionSet);
    } else if (!isNullOrUndefined(actionName) && actionName == this.DISABLE_MFA) {
      this.disableMFA();
    } else {
      this.toastr.error(Constants.GENERIC_ERROR);
    }
  }

  /**
   * This function is used to enable the multifactor authentication for the existing merchant/user.
   * 
   * @param event 
   * @param modal 
   * @param optionSet 
   */
  enableMFA(event, modal, optionSet?) {
    this.multifactorAuthService.enableMFA().subscribe(response => {
      if (response && response.success) {
        event.preventDefault();
        this.configureSuccessHeading = 'Logout ?';
        this.configureSuccessMsg = 'Multi-factor authentication has been enabled successfully.';
        this.isMFAEnabled = true;
        this.modalService.open(modal, optionSet);
      } else {
        this.toastr.error(Constants.GENERIC_ERROR);
      }
    }, error => {
      this.toastr.error(Constants.GENERIC_ERROR);
      this.clientLogger.log("Error occured while enabling MFA ", error);
    } );
  }

  /**
   * This function is used to reset the multifactor authentication options for the existing merchant/user.
   * 
   * @param event 
   * @param modal 
   * @param optionSet 
   */
  resetMFA(event, modal, optionSet?) {
    this.multifactorAuthService.resetMFA().subscribe(response => {
      if (response &&  response.success) {
        event.preventDefault();
        this.configureSuccessHeading = 'Logout ?';
        this.configureSuccessMsg = 'Multi-factor authentication has been reset successfully.';
        this.modalService.open(modal, optionSet);
      } else {
        this.toastr.error(Constants.GENERIC_ERROR);
      }
    }, error => {
      this.toastr.error(Constants.GENERIC_ERROR);
      this.clientLogger.log("Error occured while reseting MFA ", error);
    });
  }


  /**
   * This function is used to disable the multifactor authentication options for the existing merchant/user.
   */
  disableMFA() {
    this.multifactorAuthService.disableMFA().subscribe(response => {
      if (response && response.success) {
        this.toastr.success('Disabled multi-factor authentication for your account successfully.');
        this.isMFAEnabled = false;
      } else {
        this.toastr.error(Constants.GENERIC_ERROR);
      }
    }, error => {
      this.toastr.error(Constants.GENERIC_ERROR);
      this.clientLogger.log("Error occured while disabling MFA ", error);
    });
  }

  /**
   * This function is used to logout when the user wants to configure the MFA options, once the MFA is enabled for the user.
   */
  logoutAndconfigureMFAOptions() {
    this.router.navigate([this.LOGOUT_MFA]);
  }

}