import { Component, OnInit } from '@angular/core';

import { DashboardService } from '../../dashboard/dashboardService/dashboard.service';
import { Observable } from 'rxjs';
import {MerchantDetailsService} from "../../services/merchant-details.service";
import {MerchantInfo} from "../../models/MerchantDetails";
import {Constants} from "../../utils/constants";
import {isNullOrUndefined} from "util";
import {LocalStorageService} from "../../services/local-storage.service";
import {MerchantStatus} from "@models/MerchantStatus";

@Component({
  selector: 'app-fraud-alert',
  template: `
      <ng-container *ngIf="data$ | async as data">
          <ng-container *ngIf="data.merchantStatus === MERCHANT_STATUS_SUSPENDED && !data.merchantStatusReason">
              <div class="alert alert-warning mb-5" id="account-locked-error">
                  Your account has been locked. To resolve the issue, contact the Pitney Bowes support team at 
                  <a href="mailto:{{SUPPORT_EMAIL}}">{{SUPPORT_EMAIL}}</a>. We apologize for the inconvenience.
              </div>
          </ng-container>
          <ng-container *ngIf="data.merchantStatus === MERCHANT_STATUS_SUSPENDED && data.merchantStatusReason === ACH_RETURN_REASON">
              <div class="alert alert-warning mb-5" id="account-temporarily-suspended-error">
                  Your account is temporarily suspended because your last Postage Refill via ACH using bank account
                  ending in {{data.achDepositAccountNumber}} was returned. To unlock your account, contact Pitney Bowes
                  Support at <a href="mailto:{{SUPPORT_EMAIL}}">{{SUPPORT_EMAIL}}</a>.
              </div>
          </ng-container>
      </ng-container>
  `,
  styles: []
})
export class FraudAlertComponent implements OnInit {

  public data$: Observable<{}>;
//   public readonly SUPPORT_PHONE: string = Constants.SUPPORT_PHONE;
  public readonly SUPPORT_EMAIL: string = Constants.SUPPORT_EMAIL;
  public readonly MERCHANT_STATUS_SUSPENDED: string = Constants.MERCHANT_STATUS_SUSPENDED;
  public readonly ACH_RETURN_REASON: string = Constants.ACH_RETURN_REASON;

  constructor(private _dashboardService: DashboardService,
              private merchantDetailsService: MerchantDetailsService,
              private localStorageService: LocalStorageService) { }

  ngOnInit() {
    this.data$ = this._dashboardService.getUserDetails();    
    if (!isNullOrUndefined(this.localStorageService.merchantInfo)){
        this.merchantDetailsService.getMerchantStatus().subscribe((merchantStatusResp: MerchantStatus) => {
            let merchantStatus = merchantStatusResp.merchantStatus;
            this.data$.subscribe((merchantInfoObj: MerchantInfo) => {
                if(!isNullOrUndefined(merchantStatus) && merchantStatus != '' ){
                    merchantInfoObj.merchantStatus = ''+merchantStatus;
                }
                merchantInfoObj.merchantStatusReason = merchantStatusResp.merchantStatusReason;
                merchantInfoObj.achDepositAccountNumber = merchantStatusResp.achDepositAccountNumber;
                this.localStorageService.merchantInfo = merchantInfoObj;
            });
        });
    }
  }
}
