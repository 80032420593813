<div class="modal-header">
    <h2 id="modal-two-buttons" class="modal-title">Your Browser is not supported</h2>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body text-justify">
    <p>It appears that you are using a browser that is not fully supported. To get the best possible experience using
        Merchant Portal, switch to one of the browsers below:</p>
    <div>
        <img alt="" aria-hidden="true" src="assets/images/browserSupport.png" class="img-fluid">
    </div>
    <p>
        *Internet Explorer 11 and older are no longer supported.
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close()">OK</button>
</div>