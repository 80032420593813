<div class="container my-7 px-4 margin-top-100 pt-7" role="main">
  <div class="text-center">
    <svg width="139" height="135" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <defs>
        <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="a">
          <stop stop-color="var(--grad_start)" offset="0%" />
          <stop stop-color="var(--grad_end)" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M11.48 102.653c7.45 13.35 19.268 23.795 33.397 29.455a1.5 1.5 0 01-1.115 2.784c-14.77-5.916-27.118-16.829-34.902-30.777a1.5 1.5 0 112.62-1.462zm125.772-34.247a1.5 1.5 0 011.5 1.5c0 28.83-17.482 54.384-43.601 64.923a1.5 1.5 0 11-1.123-2.782c24.992-10.084 41.724-34.542 41.724-62.14a1.5 1.5 0 011.5-1.5zm-122.285.107a1.5 1.5 0 011.5 1.5c0 29.6 23.805 53.59 53.164 53.59 24.433 0 45.55-16.754 51.514-40.286a1.5 1.5 0 012.908.737c-6.297 24.852-28.604 42.55-54.422 42.55-31.021 0-56.164-25.34-56.164-56.591a1.5 1.5 0 011.5-1.5zm75.144-32.45l11.326 11.415a1.5 1.5 0 010 2.113L82.814 68.357l18.623 18.768a1.5 1.5 0 010 2.113L90.11 100.652a1.5 1.5 0 01-2.13 0l-18.606-18.75-18.604 18.75a1.5 1.5 0 01-2.13 0L37.315 89.238a1.5 1.5 0 010-2.113l18.623-18.768-18.623-18.766a1.5 1.5 0 010-2.113l11.326-11.414a1.5 1.5 0 012.13 0l18.604 18.75 18.606-18.75a1.5 1.5 0 012.13 0zm-1.065 3.187L70.44 58a1.5 1.5 0 01-2.13 0L49.706 39.25l-9.213 9.284 18.623 18.767a1.5 1.5 0 010 2.113L40.493 88.182l9.213 9.284L68.31 78.715a1.5 1.5 0 012.13 0l18.606 18.751 9.213-9.284-18.623-18.768a1.5 1.5 0 010-2.113L98.26 48.534l-9.213-9.284zM69.376 0c12.164 0 23.873 3.159 34.22 9.082a1.5 1.5 0 01-1.49 2.603C92.21 6.021 81.013 3 69.376 3 32.722 3 3 32.953 3 69.906a1.5 1.5 0 11-3 0C0 31.301 31.059 0 69.377 0zm.257 13.422c22.133 0 41.918 13.017 51.027 32.913a1.5 1.5 0 11-2.728 1.249c-8.625-18.84-27.353-31.162-48.3-31.162a52.585 52.585 0 00-25.44 6.523 1.5 1.5 0 11-1.447-2.628 55.584 55.584 0 0126.888-6.895z"
        fill="url(#a) var(--grad_start)" />
    </svg>
    <h1>That address doesn’t exist.</h1>
    <p>You may want to check for typos and try again.</p>
  </div>

</div>