import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class CarrierSetupService {

    private _carrierDetails;

    constructor(private http: HttpClient) {
    }

    /**
     * Used to save the carrier details.
     * @param carrier: carrier name.
     * @param carrierDetails: carrier form data.
     */
    saveCarrierDetails(carrierName, carrierDetails): Observable<any> {
        let url = '/carrierSetup/registerCarrier?carrier=' + carrierName;
        return this.http.post(url, carrierDetails);
    }

    /**
     * Used to get the license agreement for the carrier.
     * @param carrierName: carrier name.
     */
    getCarrierLicenseAgreement(carrierName): Observable<any> {
        let url = '/carrierSetup/carrierLicenseAgreement?carrier=' + carrierName;
        return this.http.get(url);
    }

    /**
     * This function returns shipping carrier accounts.
     * @returns {Observable<R|T>}
     */
    getShippingCarriersAccounts(): Observable<any> {
        let url = '/carrierSetup/accounts';
        return this.http.get(url);
    }

    /**
     * This function returns shipping carrier account deactivation status
     * @param account: account info.     
     * @returns {Observable<R|T>}
     */
    deactivateShippingCarrierAccount(account): Observable<any> {
        let url = '/carrierSetup/deactivate?carrier=' + account.carrierName;
        return this.http.post(url, account);
    }

    /**
     * This function returns shipping carrier account reactivation status
     * @param account: account info.     
     * @returns {Observable<R|T>}
     */
    reactivateShippingCarrierAccount(account): Observable<any> {
        let url = '/carrierSetup/reactivate?carrier=' + account.carrierName + '&accountNumber=' + account.accountNumber;
        return this.http.get(url, account);
    }

    /**
     * This function returns subscription details.
     * @returns {Observable<R|T>}
     */
    getSubscriptionAccounts(): Observable<any> {

        let url = '/carrierSetup/associatedSubscriptionAccounts';

        return this.http.get(url)
            .pipe(
                map(res => res)
            )
    }
  

    getUPSLoginURL(carrierName, originCountry): Observable<any> {
        let url = '/carrierSetup/getUPSLoginPage?carrier=' + carrierName +'&originCountryCode=' + "US";
        return this.http.get(url);
      }
    
      registerUPSAccount(carrier: string, originCountry: string, requestBody: any): Observable<any> {
        let url = '/carrierSetup/registerAccount?carrier=' + carrier +'&originCountryCode=' + "US";
        return this.http.post(url, requestBody);
      }

      isUpsProfileConfigured(): Observable<any> {
        let url = '/carrierSetup/isUpsProfileConfigured';
        return this.http.get(url);
      }

      addUPSProfile(requestBody: any): Observable<any> {
        let url = '/carrierSetup/addUpsProfile';
        return this.http.post(url, requestBody);
      }

      disableUpsProfile(): Observable<any> {
        let url = '/carrierSetup/disableUpsProfile';
        return this.http.get(url);
      }

    /**
     * This method calls payment render API for payment all render type
     * @returns {Observable<A>}
     */
    getPaymentIFrameRender(renderType: string): Observable<any> {
        const requestBody = {
            renderType: renderType
        };
        return this.http.post('/api/v1/paymentIFrame/render', requestBody)
            .pipe(
                map(res => res)
            );
    }

    /**
     *
     * @returns {Observable<any>}
     */
    getCarrierSubscriptionPaymentIframeURL(): Observable<any> {
        return this.http.get('/carrierSetup/fedexUps/paymentRender');
    }

    /**
     *
     * @returns {any}
     */
    get carrierDetails() {
        return this._carrierDetails;
    }

    /**
     *
     * @param value
     */
    set carrierDetails(value) {
        this._carrierDetails = value;
    }

    /**
     * Returns the subscription duration as string.
     * @returns {GFSDetails}
     */
    getSubscriptionTrialDuration(duration) {
        let trialDurationMap = new Map()
            .set(0, '')
            .set(1, '30 days')
            .set(2, '2 months')
            .set(3, '3 months')
            .set(4, '4 months')
            .set(5, '5 months')
            .set(6, '6 months');
        return trialDurationMap.get(duration);
    }

    /**
     * Function will return the Subscription Plan details for Merchant
     */
    getApplicableSubscriptionFee(postagePaymentMethod) {
        return this.http.get('/billingPayments/subscription/getApplicableSubscriptionFee?postagePaymentMethod=' + postagePaymentMethod)
            .pipe(
                map(res => res)
            );
    }

    /**
     * This function is used to get the carrier entitlement.
     * @param {String} developerId
     * @returns {Observable<any>}
     */
    getDeveloperCarrierList(developerId: String): Observable<any> {
        let queryString = 'developerId=' + developerId;
        return this.http.get('/carrierSetup/developerCarrierList?' + queryString);
    }

    getAdobeAgreementStatus() {
        return this.http.get('/adobe/sign/status');
    }

    getAdobeAgreement() {
        return this.http.get('/adobe/sign/form');
    }
}
