import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { AccountInfo } from "@models/AccountInfo";
import { MerchantRegistrationDetails } from "@models/MerchantRegistrationDetails";
import { Observable } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { isNullOrUndefined } from "util";
import { Constants } from "@utils/constants";
import { LocalStorageService } from "@services/local-storage.service";
import { MultifactorAuthService } from '@services/multifactor-auth.service';
import { ClientLogger } from "@services/client.logger.service";

@Injectable({
    providedIn: 'root'
})
export class CreateMerchantService {
    get merchantRegistrationDetails(): MerchantRegistrationDetails {
        return this._merchantRegistrationDetails;
    }

    set merchantRegistrationDetails(value: MerchantRegistrationDetails) {
        this._merchantRegistrationDetails = value;
    }

    merchantDetails;
    private _merchantRegistrationDetails: MerchantRegistrationDetails = new MerchantRegistrationDetails();

    constructor(private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private multifactorAuthService: MultifactorAuthService,
        private localStorageService: LocalStorageService,
        private clientLogger: ClientLogger) { }

    //Get Subscription Fee for Merchant
    getDeveloperInfo<DeveloperInfo>(developerId, referral_code, paymentMethod) {
        let queryString = 'developerId=' + developerId;
        if (referral_code) {
            queryString = queryString + "&referral_code=" + referral_code;
        }
        if (paymentMethod) {
            queryString = queryString + "&paymentMethod=" + paymentMethod;
        }
        return this.http.get<DeveloperInfo>('/registration/developerInfo?' + queryString)
            .pipe(
                map(res => res)
            );
    }

    //Validate Merchant if authorised to register or not.
    validateAccount(accountInfo: AccountInfo): Observable<any> {
        return this.http.post('/registration/validateAccount', accountInfo)
            .pipe(
                map(res => res)
            );
    }
    
    completeRegistration(accountInfo: any) {
        return this.http.post('/registration/completeRegistration', accountInfo)
            .pipe(
                map(res => res)
            );
    }

    verifyInfo(reqBody) {
        return this.http.post('/api/v1/verify', reqBody)
            .pipe(
                map(res => res)
            );
    }

    /**
     * This method is used to check if the entered email already exists with system.
      * @param {String} email
     * @returns {Observable<any>}
     */
    isEmailAlreadyRegistered(email: String) {
        return this.http.get('/registration/accountAlreadyRegistered?email=' + email)
            .pipe(
                map(res => res)
            );
    }


    /**
     * This method is used to read the data from queryParams and create the merchantRegistrationDetails object.
     */
    readAndProcessQueryParams() {
        //checking if multi-factor is enabled or not
        this.isMFAEnabled();

        this.route.queryParams.subscribe((params) => {
            let developerId = isNullOrUndefined(params['developerID']) ? params['developerId'] : params['developerID'];
            let referral_code = isNullOrUndefined(params['referral_code']) ? null : params['referral_code'];
            let paymentMethod = params['paymentMethod'];
            this.merchantDetails = this.merchantRegistrationDetails;
            if (isNullOrUndefined(developerId)) {
                developerId = this.merchantDetails.developerId;
                paymentMethod = this.merchantDetails.paymentMethod;
            }
            let referral_code_regex = /^[a-zA-Z0-9-_.]{1,25}$/;
            // If the developer ID is still null or undefined
            if (isNullOrUndefined(developerId)) {
                this.merchantDetails.errorMessage = 'Invalid/missing Developer ID.';
            } else if (!isNullOrUndefined(referral_code) && (!referral_code_regex.test(referral_code))) {
                this.merchantDetails.errorMessage = 'Invalid Referral code.';
            } else if (developerId !== this.merchantDetails.developerId) {
                this.merchantDetails.clear();
            }
            this.merchantDetails.developerId = developerId;
            this.merchantDetails.referral_code = referral_code;

            if (paymentMethod && Constants.SUPPORTED_PAYMENT_METHODS.indexOf(paymentMethod) > -1) {
                this.merchantDetails.paymentMethod = paymentMethod;
            } else {
                this.merchantDetails.paymentMethod = null;
            }

        }, (error) => {
            this.merchantDetails.errorMessage = 'Invalid/missing Developer ID.';
        });

        return this.merchantDetails;
    }

    /**
     * This function is used to get the MFA configureation from the environment veriables.
     * MFA is configurable, we can enable or disable MFA. We can read this configuration from environment veriable.
     */
    isMFAEnabled() {
        this.multifactorAuthService.isMFAEnabled().subscribe((mfaEnabledResp: any) => {
            this.localStorageService.mfaEnabled = '' + mfaEnabledResp.isMFAEnabled;
        }, (error) => {
            this.clientLogger.log(error);
            this.localStorageService.mfaEnabled = 'false';
        });
    }

}
