import { Component, OnInit } from '@angular/core';
import { Constants } from "../../utils/constants";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html'
})
export class ContactUsComponent implements OnInit {
  public readonly supportEmail: string = Constants.SUPPORT_EMAIL;
  // public readonly supportPhone: string = Constants.SUPPORT_PHONE;

  constructor() { }

  ngOnInit() {
  }

}
