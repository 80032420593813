import { Component, OnInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@utils/constants';
import { MerchantRegistrationDetails } from '@models/MerchantRegistrationDetails';
import { CreateMerchantService } from '@services/create-merchant.service';
import { ClientLogger } from "../../../services/client.logger.service";


@Component({
  selector: 'app-mfa-select',
  templateUrl: './register-multifactor-auth.component.html'
})
export class RegisterMultifactorAuthComponent implements OnInit {
  @HostBinding('class.signin') classSignin = true;
  public selectMFA = false;
  public readonly supportEmail: string = Constants.SUPPORT_EMAIL;
  // public readonly supportPhone: string = Constants.SUPPORT_PHONE;

  constructor(private router: Router,
    private createMerchantService: CreateMerchantService,
    protected clientLogger: ClientLogger) {
    this.clientLogger = clientLogger;
  }

  ngOnInit() {
  }

  /**
   * This function is used when the user choosed the MFA while registeration.
   * User would be redirected to address verification page once the user clicks the continue button.
   */
  mfaContinue() {
    this.clientLogger.log('User selected Multi-factor auth - ');
    // Navigate to address Verification page
    this.createMerchantService.merchantRegistrationDetails.selectedMFA = this.selectMFA;
    this.router.navigate([Constants.CREATE_VERIFY_ADDRESS_ROUTE]);
  }

}
