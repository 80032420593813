import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {LogoutComponent} from './logout/logout.component';
import {LoginErrorComponent} from './login-error/login-error.component';
import {FormsModule} from '@angular/forms';
import { ConfigureMultifactorAuthComponent } from './multifactor/configure-multifactor-auth/configure-multifactor-auth.component';
import { RegisterMultifactorAuthComponent } from './multifactor/register-multifactor-auth/register-multifactor-auth.component';
import { InfoMultifactorAuthComponent } from './multifactor/info-multifactor-auth/info-multifactor-auth.component';
import { AuthRouting } from './auth.routing';
import { SharedModule } from '@shared/shared.module';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        AuthRouting
    ],
    declarations: [
        LoginComponent, 
        LogoutComponent, 
        LoginErrorComponent, 
        ConfigureMultifactorAuthComponent, 
        RegisterMultifactorAuthComponent,
        InfoMultifactorAuthComponent]
})
export class AuthModule {
}
