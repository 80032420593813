import { Component, OnInit, HostBinding} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: []
})
export class PageNotFoundComponent implements OnInit {
  @HostBinding('class.errors') true;

    constructor(public router: Router) { }

  ngOnInit() {
  }

}
