// http-service-interceptor.ts
import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { Constants } from "../utils/constants";

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
    constructor(private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            return event;
        },
            (err) => {
                if (err instanceof HttpErrorResponse && err.status === Constants.HTTP_401_UNAUTHORISED_RESP_CODE) {
                    this.router.navigate(['/logout']);
                }
                return err;
            }));

    }
}