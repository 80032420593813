import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { ClientLogger } from './client.logger.service';
import { Constants } from "../utils/constants";
import { Observable } from 'rxjs';

const MFA_SMS_DESC = "A security code will be delivered by SMS to your mobile device. SMS charges may apply.";
const MFA_VOICE_CALL_DESC = "Our automated systems will call your designated phone number to provide you with the security code.";
const MFA_OKTA_VERIFY_DESC = "Okta Verified app must be started on your mobile device, which will generate a six-digit numbers code. The numbers are generated using the industry standard.";
const MFA_GOOGLE_AUTH_DESC = "Google Authenticator app must be started on your mobile device to generate a six-digit numbers code. The allowable clock skew is two minutes. After five unsuccessful attempts, the your account will be locked and will be reset by an administrator.";
const MFA_RSA_DESC = "You must use an RSA hardware dongle device or soft token to generate an authentication number code. The numbers are generated using a built-in clock and the card's factory-encoded random key.";
const MFA_SECURITY_QUESTION_DESC = "You must enter the correct response to a security question, Which you have selected from a list of possible questions.";
const MFA_EMAIL_DESC = "You will receive a security code through email.";

@Injectable({
    providedIn: 'root'
})
export class MultifactorAuthService {

    constructor(private http: HttpClient, protected clientLogger: ClientLogger) {
    }

    /**
     * Used to check if the loged in merchant has allready configured the MFA or not.
     */
    getUserMFAGroupMembership(): any {
        return this.http.get('/auth/userMFAGroupMembership')
            .pipe(
                map(res => res)
            );
    }

    /**
     * Used to reset the mulit-factor authentication options. On next login user would be asked to configure mulit-factor authentication options.
     */
    resetMFA(): any {
        return this.http.post('/auth/resetMultiFactor', {})
            .pipe(
                map(res => res)
            );
    }

    /**
     * Used to enable mulit-factor authentication. On next login user would be asked to configure mulit-factor authentication options.
     */
    enableMFA(): any {
        return this.http.put('/auth/enableMultiFactor', {})
            .pipe(
                map(res => res)
            );
    }

    /**
     * Used to disable mulit-factor authentication. On next login user would not be asked to enter mulit-factor authentication details.
     */
    disableMFA(): any {
        return this.http.delete('/auth/removeMultiFactor')
            .pipe(
                map(res => res)
            );
    }

    /**
     * Used to check if the loged in merchant has allready configured the MFA or not.
     */
    getMFAGroupName(): any {
        return this.http.get('/auth/getMultiFactorGroupName')
            .pipe(
                map(res => res)
            );
    }

    /**
     * This function is used to check if the mulit-factor authentication is enabled for the given merchant.
     */
    isMFAEnabled() {
        return this.http.get('/auth/isMFAEnabled')
            .pipe(
                map(res => res)
            );
    }

    /**
     * 	Okta Verify  -- O
     * 	SMS Authentication --S
     * 	Voice Call Authentication  -- V
     * 	Google Authenticator  --G
     * 	RSA SecurID -R
     * 	Security Question -SQ  
     * 	Email Authentication --E
     * 
     *  Group Name Example - MFA-Group-MerchantPortal_S_V, means this group will have SMS and Voice call.
     */
    generateMFADetailsMap() {
        return new Observable(obs => {
            let mainMFADetailsMap = new Map();
            this.getMFAGroupName().subscribe(response => {
                let groupName = response.multifactorGroupName;
                let factorEnabledString = groupName.replace('MFA_Group_MerchantPortal_', '');
                let factorEnabled = factorEnabledString.split("_");
                factorEnabled.forEach(factor => {
                    switch (factor) {
                        case 'O': {
                            // Okta Verify  -- O 
                            mainMFADetailsMap.set(Constants.MFA_OKTA_VERIFY_HEADING, MFA_OKTA_VERIFY_DESC);
                            break;
                        }
                        case 'S': {
                            // SMS Authentication --S
                            mainMFADetailsMap.set(Constants.MFA_SMS_HEADING, MFA_SMS_DESC);
                            break;
                        }
                        case 'V': {
                            // SMS Authentication --S
                            mainMFADetailsMap.set(Constants.MFA_VOICE_CALL_HEADING, MFA_VOICE_CALL_DESC);
                            break;
                        }
                        case 'G': {
                            // Google Authenticator  --G 
                            mainMFADetailsMap.set(Constants.MFA_GOOGLE_AUTH_HEADING, MFA_GOOGLE_AUTH_DESC);
                            break;
                        }
                        case 'R': {
                            // RSA SecurID -R
                            mainMFADetailsMap.set(Constants.MFA_RSA_HEADING, MFA_RSA_DESC);
                            break;
                        }
                        case 'SQ': {
                            // Security Question -SQ
                            mainMFADetailsMap.set(Constants.MFA_SECURITY_QUESTION_HEADING, MFA_SECURITY_QUESTION_DESC);
                            break;
                        }
                        case 'E': {
                            // SMS Authentication --E
                            mainMFADetailsMap.set(Constants.MFA_EMAIL_HEADING, MFA_EMAIL_DESC);
                            break;
                        }
                        default: {
                            //statements; 
                            this.clientLogger.log('No matching MFA found.');
                            break;
                        }
                    }
                });
                obs.next(mainMFADetailsMap);
                obs.complete();
                return { success: true };
            }, (error) => {
                obs.error(error);
                return { success: false };
            });
        });
    }

}
