<div class="signin-wrapper signin-data col-8 mt-3 mb-4">
    <div class="signin-box">
        <div class="d-flex justify-content-between mb-3">
            <div class="form-group form-group-switch mb-0">
                <h2 class="flex-grow-1 my-0 mr-3" id="mfa-header">{{'MFA.HEADER.MULTI_FACTOR_AUTHENTICATION' | translate}}</h2>
                <label for="switch8" class="switch switch-text switch-pill switch-primary" aria-label="Label">
                    <input id="switch8" type="checkbox" class="switch-input" name="selectMFA" [(ngModel)]="selectMFA"
                        checked>
                    <span class="switch-label" data-on="Yes" data-off="No" aria-hidden="true"></span>
                    <span class="switch-handle" aria-hidden="true"></span>
                </label>
            </div>
            <div class="mr-2">
                <div class="d-inline-block mr-5 mt-2">
                    <a class="text-blue-50" href="mailto:{{supportEmail}}" area-hidden="true" id="mfa-support-email">
                        <i class="pbi-icon-mini pbi-mail"></i>{{'MFA.HEADER.EMAIL' | translate}}
                    </a>
                </div>
                <!-- <div class="d-inline-block mt-2">
                    <a class="text-blue-50" href="tel:{{supportPhone}}" id="mfa-support-phone">
                        <i class="pbi-icon-mini pbi-telephone"></i>{{supportPhone}}
                    </a>
                </div> -->
            </div>
        </div>
        <div class="mb-5">
            <app-info-multifactor-auth></app-info-multifactor-auth>
        </div>
        <div class="row text-center my-3 mt-7">
            <div class="col-sm-12">
                <div class="form-group  float-left">
                    <span>
                        <button type="button" class="btn-page-primary btn" id="mfa-continue-btn"
                            (click)="mfaContinue()">{{'MFA.HEADER.CONTINUE' | translate}}
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <script>
    _satellite.track('register-multifactor-auth');
</script> -->