import { Component, Inject, OnInit } from '@angular/core';
import { routerAnimations } from './router-animations';
const STORAGE_KEY = 'angular4Session';
declare var OptanonWrapper: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [],
    animations: [routerAnimations]
})
export class AppComponent implements OnInit {
    constructor() {
        this.loadOneTrustScript();
    }

    ngOnInit() {
    }

    public getRouterOutletState(outlet) {
        return outlet.isActivated ? outlet.activatedRoute : '';
    }

    loadOneTrustScript() {
        const script = document.createElement('script');
        script.type = "text/javascript";
        if (window.location.hostname === "www.pbshippingmerchant.pitneybowes.com") {
            script.src = "https://cdn.cookielaw.org/consent/0191bc1b-988e-7c0f-8654-34ed8f86ded7/OtAutoBlock.js";
        } else {
            script.src = "https://cdn.cookielaw.org/consent/0191b86d-906e-7bd7-a9ad-32197e324ede-test/OtAutoBlock.js";
        }
        script.async = true;
        script.onload = () => {
            this.initializeOneTrust();
        };
        document.head.appendChild(script);

        const apiScript = document.createElement('script');
        apiScript.type = 'text/javascript';
        apiScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
        apiScript.charset = 'UTF-8';
        apiScript.async = true;
        if (window.location.hostname === "www.pbshippingmerchant.pitneybowes.com") {
            apiScript.setAttribute('data-domain-script', '0191bc1b-988e-7c0f-8654-34ed8f86ded7');
        } else {
            apiScript.setAttribute('data-domain-script', '0191b86d-906e-7bd7-a9ad-32197e324ede-test');
        }
        document.head.appendChild(apiScript);
    }

    initializeOneTrust(): void {
        if (typeof OptanonWrapper === 'function') {
            OptanonWrapper();
        }
    }

}
