import {Component, HostBinding, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Constants} from "@utils/constants";

@Component({
  selector: 'app-contact-support-team',
  templateUrl: './contact-support-team.component.html'
})
export class ContactSupportTeamComponent implements OnInit {

    public readonly supportEmail: string = Constants.SUPPORT_EMAIL;
   


    @HostBinding('class.errors') true;

    constructor(public router: Router) { }

    ngOnInit() {}

}
