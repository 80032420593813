import {Injectable} from "@angular/core";
import {LocalStorageService} from "./local-storage.service";
import {isNullOrUndefined} from "util";

@Injectable({
    providedIn: 'root'
})
export class ClientLogger {

    private doLogging: boolean = false;

    constructor(private localStorageService: LocalStorageService) {
        if(localStorageService.merchantInfo){
            let clientLogging = localStorageService.merchantInfo.clientLogging;
            if(!isNullOrUndefined(clientLogging) && clientLogging === 'true'){                
                this.doLogging = true;
            }
        }
    }

    public log(object: any, ...optionalParams: any[]) {

        if (this.doLogging) {
            console.log(object, optionalParams);
        }
    }
}