import {RouterModule, Routes} from '@angular/router';
import {NgModule} from "@angular/core";
import { ConfigureMultifactorAuthComponent } from './multifactor/configure-multifactor-auth/configure-multifactor-auth.component';
import { CreateFooterComponent } from '@shared/create-footer/create-footer.component';
import { HeaderComponent } from '@shared/header/header.component';
import { RegisterMultifactorAuthComponent } from './multifactor/register-multifactor-auth/register-multifactor-auth.component';
import { RoutesInterceptor } from 'app/helpers/routes.interceptor';

const routes: Routes = [  
    {
        // the below path is used at the time of registeration for new user.
        path: 'register-mfa',
        children: [
            {
                path: '',
                component: RegisterMultifactorAuthComponent                
            },
            {
                path: '',
                outlet: 'footer',
                component: CreateFooterComponent
            }
        ]
    },  
    {
        // the below path is used for existing user after login.
        path: 'configure-mfa',
        children: [
            {
                path: '',
                outlet: 'header',
                component: HeaderComponent
            },                        
            {
                path: '',
                component: ConfigureMultifactorAuthComponent,
                canActivate: [RoutesInterceptor]
            },
            {
                path: '',
                outlet: 'footer',
                component: CreateFooterComponent
            }
        ]
    }    
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AuthRouting {
}

