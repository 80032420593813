import { HttpClient, HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from "@angular/common";
import { RoutesInterceptor } from "./helpers/routes.interceptor";
import { SharedModule } from "./shared/shared.module";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthModule } from './auth/auth.module';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { HttpResponseInterceptor } from "./helpers/http.response.interceptor";
import { CookieService } from 'ngx-cookie-service';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';

export function HttpLoaderFactory(http:HttpClient){
    return new MultiTranslateHttpLoader(http, [
      {prefix: "./assets/i18n/New_Merchant/", suffix: ".json"},
      {prefix: "./assets/i18n/Existing_Merchant/", suffix: ".json"},
  ]);
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        SharedModule,
        AuthModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
         CommonModule,
        HttpClientModule,
        HttpClientXsrfModule,
        NgIdleKeepaliveModule.forRoot(),
        NgbModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        TranslateModule.forRoot({
            loader:{
                provide: TranslateLoader,
                useFactory:HttpLoaderFactory,
                deps:[HttpClient]
        }})
    ],
    providers: [RoutesInterceptor,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpResponseInterceptor,
            multi: true
        },
        CookieService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
