import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ach-time-table',
  templateUrl: './ach-time-table-modal.component.html'
})
export class AchTimeTableModal implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
