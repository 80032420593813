import {Component, HostBinding, OnInit} from '@angular/core';
import {Constants} from "../../utils/constants";
import {ActivatedRoute, Router} from "@angular/router";
import {isNullOrUndefined} from "util";

@Component({
    selector: 'app-login-error',
    templateUrl: './login-error.component.html',
    styleUrls: []
})
export class LoginErrorComponent implements OnInit {
    @HostBinding('class.signin') classSignin = true;

    public readonly supportEmail: string = Constants.SUPPORT_EMAIL;
    // public readonly supportPhone: string = Constants.SUPPORT_PHONE;
    public errorMessage: string;
    public isIframe:boolean = false;

    private readonly DEFAULT_ERROR_MESSAGE: string = 'The user is not authorized to access Merchant Portal.';

    constructor(private route: ActivatedRoute, private router: Router) {
        this.route.queryParams.subscribe((params) => {
            this.errorMessage = params['errorMessage'];
            this.isIframe = (!isNullOrUndefined(params['isIframe']) && params['isIframe'] == 'true');

            if(isNullOrUndefined(this.errorMessage) || this.errorMessage.trim() == '') {
                this.errorMessage = this.DEFAULT_ERROR_MESSAGE;
            }

        }, (error) => {
            this.isIframe = false;
            this.errorMessage = this.DEFAULT_ERROR_MESSAGE;
        });
    }

    redirectToLogin() {
        this.router.navigate(['logout']);
    }

    ngOnInit() {
    }

}
