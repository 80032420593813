import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public languageSelector: Map<string, string>;

  public languageSelected = 'English';

  constructor() {
    this.populateLanguageList();
  }

  /**
   * This function returns the list of Language which can be loaded into Dropdown
   * 
   */

   getLanguageSelected(){
     return this.languageSelected;
   }

   setLanguageSelected(language){
    this.languageSelected = language;
   }

  getLanguageList() {
    return this.languageSelector;
  }
  populateLanguageList() {
    this.languageSelector = new Map();
    this.languageSelector.set("English-US", "English");
    this.languageSelector.set("Française", "French")
  }
}
