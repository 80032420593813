import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-browser-support',
    templateUrl: './browser-support-modal.component.html'
})

export class BrowserSupportModal implements OnInit {

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
    }

}
