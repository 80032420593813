import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastFourDigit'
})
export class LastFourDigitPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let str;
    if (value) {
      value = value.replace(/.(?=.{4,}$)/g, '');
      str = value.replace(/\*/g, '');
      str = '. . .' + str;
    }
    return str;
  }

}
