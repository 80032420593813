<div class="page-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 id="mfa-auth-header">Multi-factor Authentication</h1>
      </div>
    </div>
  </div>
</div>

<!-- use mt-7 unless the first element is an H2 -->
<div class="container-fluid mt-4 pb-7">
  <div class="row justify-content-center">
    <div class="col-9">
      <div class="card card-default rounded border-0 h-100 box-shadow">
        <div class="card-body">
          <div class="col-sm-12">
            <app-info-multifactor-auth></app-info-multifactor-auth>
          </div>
          <div class="col-sm-12 mt-5">
            <div class="form-group  float-left">
              <span class="mr-3" *ngIf="!isMFAEnabled">
                <button type="button" class="btn btn-page-primary" id="MFA-enable-btn"
                  (click)="openConfigureMFAConfirmModal($event, ENABLE_MFA, configureMFAModal, {ariaLabelledBy: 'modal-two-buttons'})">Enable
                </button>
              </span>
              <span class="mr-3" *ngIf="isMFAEnabled">
                <button type="button" class="btn btn-page-primary" id="MFA-reset-btn"
                  (click)="openConfigureMFAConfirmModal($event, RESET_MFA, configureMFAModal, {ariaLabelledBy: 'modal-two-buttons'})">Reset
                </button>
              </span>
              <span *ngIf="isMFAEnabled">
                <button type="button" class="btn btn-secondary" id="MFA-disable-btn"
                  (click)="openConfigureMFAConfirmModal($event, DISABLE_MFA, configureMFAModal, {ariaLabelledBy: 'modal-two-buttons'})">Disable
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #configureMFAModal let-close="close" let-dismiss="dismiss">
  <div class="modal-header">
    <h2 id="modal-two-buttons" class="modal-title">
      {{configureAction | titlecase}} Multi-factor Authentication
    </h2>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()" id="configure-MFA-modal-dismiss">
      <span aria-hidden="true"></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-justify" id="configure-MFA-info"> Are you sure you would like to {{configureAction}} multi-factor
      authentication for your account ?</p>
  </div>
  <div class="modal-footer">
    <button  type="button" class="btn btn-page-primary"
      (click)="configureMFA($event, configureAction, configureSuccessModal,  {ariaLabelledBy: 'modal-two-buttons'}); close()"
      id="configure-MFA-yes-btn">Yes</button>
    <button  type="button" class="btn btn-secondary" (click)="dismiss()" id="configure-MFA-no-btn">No</button>
  </div>
</ng-template>

<ng-template #configureSuccessModal let-close="close" let-dismiss="dismiss">
  <div class="modal-header">
    <h2 id="modal-two-buttons" class="modal-title">
      {{configureSuccessHeading}}
    </h2>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()" id="configure-success-modal-dismiss">
      <span aria-hidden="true"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="alert alert-success alert-border" role="alert" id="av-mfa-selected">
      <b>{{configureSuccessMsg}}</b>
    </div>
    <p class="text-justify" id="configure-success-info">Note:- To configure multi-factor authentication options you have
      to logout or you can configure while next login. Choose 'Yes' if you want to logout now.</p>
  </div>
  <div class="modal-footer">
    <button  type="button" class="btn btn-page-primary" (click)="logoutAndconfigureMFAOptions()"
      id="configure-success-yes-btn">Yes</button>
    <button  type="button" class="btn btn-secondary" (click)="dismiss();"
      id="configure-success-no-btn">No</button>
  </div>
</ng-template>