<ng-container>
    <div class="my-7 px-4 text-center" role="main">
        <div class="text-center">
            <svg width="139" height="135" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <defs>
                    <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="a">
                        <stop stop-color="var(--grad_start)" offset="0%" />
                        <stop stop-color="var(--grad_end)" offset="100%" />
                    </linearGradient>
                </defs>
                <path
                    d="M11.48 102.653c7.45 13.35 19.268 23.795 33.397 29.455a1.5 1.5 0 01-1.115 2.784c-14.77-5.916-27.118-16.829-34.902-30.777a1.5 1.5 0 112.62-1.462zm125.772-34.247a1.5 1.5 0 011.5 1.5c0 28.83-17.482 54.384-43.601 64.923a1.5 1.5 0 01-1.123-2.782c24.992-10.084 41.724-34.542 41.724-62.14a1.5 1.5 0 011.5-1.5zm-122.285.107a1.5 1.5 0 011.5 1.5c0 29.6 23.805 53.59 53.164 53.59 24.433 0 45.55-16.754 51.514-40.286a1.5 1.5 0 012.908.737c-6.297 24.852-28.604 42.55-54.422 42.55-31.021 0-56.164-25.34-56.164-56.591a1.5 1.5 0 011.5-1.5zM70.12 83.448c5.82 0 10.533 4.75 10.533 10.604 0 5.853-4.713 10.603-10.533 10.603-5.82 0-10.533-4.75-10.533-10.603 0-5.854 4.714-10.604 10.533-10.604zm0 3c-4.157 0-7.533 3.402-7.533 7.604s3.376 7.603 7.533 7.603c4.158 0 7.533-3.401 7.533-7.603s-3.375-7.604-7.533-7.604zm9.104-55.927a1.5 1.5 0 011.5 1.5v45.165a1.5 1.5 0 01-1.5 1.5H61.017a1.5 1.5 0 01-1.5-1.5V32.02a1.5 1.5 0 011.5-1.5h18.207zm-1.5 3H62.517v42.165h15.207V33.52zM69.377 0c12.163 0 23.872 3.159 34.22 9.082a1.5 1.5 0 01-1.49 2.603C92.21 6.021 81.012 3 69.376 3 32.72 3 3 32.953 3 69.906a1.5 1.5 0 11-3 0C0 31.301 31.059 0 69.377 0zm.256 13.422c22.133 0 41.918 13.017 51.027 32.913a1.5 1.5 0 11-2.728 1.249c-8.625-18.84-27.353-31.162-48.3-31.162a52.585 52.585 0 00-25.44 6.523 1.5 1.5 0 11-1.447-2.628 55.584 55.584 0 0126.888-6.895z"
                    fill="url(#a) var(--grad_start)" fill-rule="nonzero" />
            </svg>
        </div>
            <h1>{{'DASHBOARD.ANALYTICAL_DASHBOARD.NO_DATA_FOUND' | translate}}</h1>
    </div>
</ng-container>