<ng-template #basicMessage let-modal>
  <div class="modal-header">
    <h2 id="trademark-notices-header" class="modal-title">Trademark notices</h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()" id="trademark-notices-close">
      <span aria-hidden="true"></span>
    </button>
  </div>
  <div class="modal-body">
    <p id="trademark-notices-info">The use of the following trademark in Pitney Bowes Shipping API has been granted
      by permission of their owners.</p>
    <p id="trademark-notices-info2">The FedEx service marks are owned by Federal Express Corporation and are used by
      permission.</p>
    <p id="trademark-notices-info3">UPS, the UPS shield trademark, the UPS Ready mark, the UPS Developer Kit mark
      and the Color Brown are trademarks of United Parcel Service of America, Inc. All Rights Reserved.</p>
  </div>
</ng-template>
<a href="" (click)="open($event, basicMessage, {ariaLabelledBy: 'modal-basic-title'})"
  id="trademark-notices-target">Trademark
  notices</a>