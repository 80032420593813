import { Component, OnInit } from '@angular/core';
import { ClientLogger } from '@services/client.logger.service';
import { MultifactorAuthService } from '@services/multifactor-auth.service';
import { Constants } from "../../../utils/constants";

@Component({
  selector: 'app-info-multifactor-auth',
  templateUrl: './info-multifactor-auth.component.html'
})
export class InfoMultifactorAuthComponent implements OnInit {

  public mainMFADetailsMap;

  constructor(protected clientLogger: ClientLogger,
    private multifactorAuthService: MultifactorAuthService) {
    this.clientLogger = clientLogger;
  }

  ngOnInit() {
    this.getGeneratedMFADetailsMap();
  }

  /**
   * This function is used to get the generated multi-factor authentication details map
   */
  getGeneratedMFADetailsMap() {
    this.multifactorAuthService.generateMFADetailsMap().subscribe(response => {
      this.mainMFADetailsMap = response;
    });
  }

  /**
   * This function is used to get card side image for corresponding multi-factor authentication option type.
   * 
   * @param mfaOption 
   */
  getSideImage(mfaOption) {
    let imageName;
    if (mfaOption) {
      switch (mfaOption) {
        case Constants.MFA_OKTA_VERIFY_HEADING: {
          // Okta Verify  -- O 
          imageName = 'pbi-entity';
          break;
        }
        case Constants.MFA_SMS_HEADING: {
          // SMS Authentication --S
          imageName = 'pbi-newsletter';
          break;
        }
        case Constants.MFA_VOICE_CALL_HEADING: {
          // SMS Authentication --S
          imageName = 'pbi-phone';
          break;
        }
        case Constants.MFA_GOOGLE_AUTH_HEADING: {
          // Google Authenticator  --G 
          imageName = 'pbi-dashboard';
          break;
        }
        case Constants.MFA_RSA_HEADING: {
          // RSA SecurID -R
          imageName = 'pbi-key';
          break;
        }
        case Constants.MFA_SECURITY_QUESTION_HEADING: {
          // Security Question -SQ
          imageName = 'pbi-lock';
          break;
        }
        case Constants.MFA_EMAIL_HEADING: {
          // SMS Authentication --E
          imageName = 'pbi-mail';
          break;
        }
        default: {
          //statements; 
          this.clientLogger.log('No matching MFA-Options found.');
          break;
        }
      }
    } else {
      this.clientLogger.log('No matching MFA-Options found.');
    }
    return imageName;
  }


}
