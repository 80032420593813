import { Injectable } from "@angular/core";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { SessionService } from "./session.service";
import { Constants } from "../utils/constants";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ClientLogger } from "./client.logger.service";
import { Router } from "@angular/router";


@Injectable({
    providedIn: 'root'
})
export class MonitoringUserActivityService {
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    closeResult: string;

    constructor(private sessionService: SessionService, private modalService: NgbModal, protected clientLogger: ClientLogger, private router: Router) {
        this.clientLogger = clientLogger;

    }

    /**
     * This method would be used to set the ideal time out related properties.
     * @param idle
     * @param keepalive
     */
    setIdealSettings(idle: Idle, keepalive: Keepalive, template) {
        // sets an idle timeout of 5 seconds, for testing purposes.
        idle.setIdle(Constants.IDEAL_TIME);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        idle.setTimeout(Constants.TIME_OUT);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
        idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.clientLogger.log('Redirecting to login');
            this.open(template);
        });
        idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
        idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

        // sets the ping interval to 15 seconds
        keepalive.interval(Constants.KEEP_ALIVE_PING_INTERVAL);

        keepalive.onPing.subscribe(() => this.lastPing = new Date());

        //For reset of the idel time, call the reset();
        this.reset(idle);
    }

    reset(idle: Idle) {
        idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }

    open(template) {
        this.clientLogger.log('template', template);
        localStorage.clear();
        localStorage.setItem('loggedIn', 'false');
        this.modalService
            .open(template, { keyboard: false, backdrop: 'static', ariaLabelledBy: 'modal-dismissable-controls-only' })
            .result.then(
                result => {
                    this.clientLogger.log('Redirecting to login');
                    this.router.navigate(['/logout']);
                }
            )
    }

}