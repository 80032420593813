import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'app-create-footer',
  templateUrl: './create-footer.component.html',
  styleUrls: []
})
export class CreateFooterComponent implements OnInit {
  @HostBinding('class.site-footer')
  public date: any;

  constructor() { }

  ngOnInit() {
    let date = new Date();
    this.date = date.getFullYear();
  }

}
