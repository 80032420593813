import {Injectable} from '@angular/core';
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {DEFAULT_INTERRUPTSOURCES, Idle} from "@ng-idle/core";
import {Keepalive} from "@ng-idle/keepalive";
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private logoutUrl: string;

    constructor(private http: HttpClient, private authService: AuthService, private idle: Idle,
                private keepalive: Keepalive, private cookieService: CookieService) {
    }

    /**
     * This method is used to get the session.
     * @returns {Observable<any>}
     */
    getSession() {
        return this.http.get('/session/getSession')
            .pipe(
                map(res => res)
            );
    }

    /**
     * This method is used to set some attribute to session.
     * @param {string} returnUrl
     * @returns {Observable<any>}
     */
    setSession(returnUrl: string) {
        return this.http.get('/session/setSession')
            .pipe(
                map(res => res)
            );
    }

    /**
     * This method is used to destroy the express-session and the local cache.
     */
    destroySession() {
        localStorage.clear();
        this.authService.getLogoutUrl().subscribe(
            response => {
                this.logoutUrl = response["logoutUrl"];
            },
            () => {
                console.log("Error while fetching Logout URL");
            }
            ,
            () => {
                this.cookieService.delete('mp_sessn');
                window.location.href = this.logoutUrl;
            }
        );
    }


}
