import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { MerchantInfo } from "../../models/MerchantDetails";
import { LocalStorageService } from "../../services/local-storage.service";
import { isNullOrUndefined } from "util";
import { Constants } from "../../utils/constants";
import * as moment from "moment";
import 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  PostageRefill_NAV = {
    title: 'postageRefills',
    name: 'Postage Refills',
    description: 'Schedule postage refill, view your postage refills history.',
    icon: 'pbi-money',
    link: '/billing',
    params: { tab: 'postageRefill' }
  };

  SubscriptionHistory_NAV = {
    title: 'subscriptionHistory',
    name: 'Subscription & Payment Info',
    description: 'View your Subscription or payment history.',
    icon: 'pbi-money-coins',
    link: '/billing',
    params: { tab: 'subscription' }
  };

  OnlineSupport_NAV = {
    title: 'onlineSupport',
    name: 'Online Support',
    description: 'Find answers to most frequently asked questions.',
    icon: 'pbi-help-circle',
    link: '/online-support'
  };

  MakeAPayment_NAV = {
    title: 'makePayment',
    name: 'Make a Payment',
    description: 'Make a payment or manage payment method.',
    icon: 'pbi-coins',
    link: '/billing',
    params: { tab: 'paymentMethod' }
  };

  AchOnlineSupport_NAV = {
    title: 'ach',
    name: 'ACH',
    description: 'Pay directly from bank account.',
    icon: 'pbi-bank',
    link: '/online-support/content/ach-electronic-bank-account-transfer'
  };

  Banners = [{
    "id": "Banner_ACH",
    "readMoreLink": "/online-support/content/ach-electronic-bank-account-transfer",
    "title": "ACH Electronic Funds Transfer",
    "description": "ACH (Automated Clearing House) Electronic Funds Transfer is one of the payment method you can choose to fund your USPS shipments.ACH transfer directly debits your bank account to add funds to your postage account.",
    "isVisible": true,
    "isActive": false
  },
  {
    "id": "Banner_MFA",
    "readMoreLink": "/auth/configure-mfa",
    "title": "Multi-factor Authentication",
    "description": "Multi-factor authentication, also known as two-step verification, adds an additional security factor, preventing unauthorized access to your account.",
    "isVisible": true,
    "isActive": false
  },
  {
    "id": "cancel-shipment",
    "readMoreLink": "/online-support/content/usps-cancel-shipment",
    "title": "Automated Cancel Shipment Feature",
    "description": "Did you know that Pitney Bowes will automatically refund any unused shipping labels ?",
    "isVisible": true,
    "isActive": false
  }
  ];

  private userAccountDetails: MerchantInfo[];

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
  }

  /**
   * Used to get the merchant account details.
   * @returns {Observable<any>}
   */
  getUserDetails() {
    let merchantInfoObj = this.localStorageService.merchantInfo;
    if (!isNullOrUndefined(merchantInfoObj)) {
      return new Observable(obs => {
        obs.next(merchantInfoObj);
        obs.complete();
      });
    } else {
      // getting the user details from backend.
      return this.generateUserDetailsReq();

    }
  }

  /**
   *Used to generate the request for merchant details and save the response in the local storage.
   * @returns {Observable<any>}
   */
  generateUserDetailsReq() {
    return new Observable(obs => {
      let queryString = 'selectedAccountDevId=' + this.localStorageService.selectedAccountDevId;
      const httpOptions = {
        headers: new HttpHeaders({
          'Cache-control': 'no-cache',
          'Expires': '0',
          'Pragma': 'no-cache'
        })
      };
      this.http.get<MerchantInfo>('/user/details?' + queryString, httpOptions)
        .subscribe((merchantInfoObj: MerchantInfo) => {
          let status = merchantInfoObj[Constants.STATUS];
          if (status && (status.toLowerCase() === Constants.USER_Found)) {
            if (merchantInfoObj.timeZone == null) {
              //If user time zone found in DB then use local time zone
              merchantInfoObj.timeZone = moment.tz.guess();
            } else {
              //If user time zone found in DB then use offset to show on reports.
              merchantInfoObj.timeZoneOffset = "(UTC" + moment.tz(merchantInfoObj.timeZone).format('Z') + ")";
            }
            this.localStorageService.merchantInfo = merchantInfoObj;
            obs.next(this.localStorageService.merchantInfo);
            obs.complete();
            return { success: true };
          } else {
            obs.error("User not found");
            return { success: false };
          }
        }, (error) => {
          obs.error(error);
        });
    });
  }

  /**
   * Used to get the multiple account associated with merchant.
   * @returns {Observable<any>}
   */
  getUserAccountDetails() {
    if (!isNullOrUndefined(this.userAccountDetails)) {
      return new Observable(obs => {
        obs.next(this.userAccountDetails);
        obs.complete();
      });
    } else {
      return this.http.get<MerchantInfo[]>('/user/accountDetails')
        .pipe(map(res => res));
    }
  }

  /**
   * Gets the Account Balance for the Customer
   */
  getAccountBalance() {
    return this.http.get('/user/getBalance')
      .pipe(
        map(res => res)
      );
  }

  /**
   * Gets the get auto refill details
   */
  getAutoRefill() {
    return this.http.get('/user/autorefill').pipe(map(res => res));
  }

  /**
   * update  the auto refill details
   * @param {number} threshold
   * @param {number} addAmount
   * @returns {Observable<any>}
   */
  updateAutoRefill(threshold: number, addAmount: number) {
    let param = {
      threshold: threshold,
      addAmount: addAmount
    };
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = '/user/autorefill';

    return this.http.put(url, {
      headers: headers,
      params: param
    }
    ).pipe(map(res => res));
  }

  /**
   * Gets the get auto Refund details
   */
  getAutoRefundInfo(merchantId: string): Observable<any> {
    return this.http.get<any>('/user/autoRefund').pipe(map(res => res));
  }

  /**
  * update  the auto refund details
  * @param {boolean} enabled
  * @param {number} feePercentage
  * @returns {Observable<any>}
  */
  UpdateAutoRefund(enabled: boolean, feePercentage: number, merchantId: string): Observable<any> {
    let url = '/user/autoRefund';

    const body = {
      "enabled": enabled,
      "feePercentage": feePercentage
    };
    return this.http.put<any>(url, body).pipe(map(res => res));
  }

  /**
   * post the auto refund details
   * @param {boolean} enabled
   * @param {number} feePercentage
   * @returns {Observable<any>}
   */
  enableAutoRefund(enabled: boolean, feePercentage: number, merchantId: string): Observable<any> {
    let url = '/user/autoRefund';

    const body = {
      "enabled": enabled,
      "feePercentage": feePercentage
    };

    return this.http.post<any>(url, body).pipe(map(res => res));
  }


  /** add fund do refill postage with certian fixed amount **/
  addFund(amount: number) {
    let param = { amount: amount };
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = '/user/fund';
    return this.http.post(url, {
      headers: headers,
      params: param
    })
      .pipe(map(res => res));
  }

  /** Provide Data for dashboard Navigation module*/
  getNavElements() {
    let data = [
      this.PostageRefill_NAV,
      this.OnlineSupport_NAV
    ];
    if (this.localStorageService.merchantInfo.isSubscribed) {
      data.splice(1, 0, this.SubscriptionHistory_NAV);
    }
    return data;
  }

  /** Provide Data for dashboard Navigation module in accoutn with LOC account*/
  getNavLOCElements() {
    let data = [
      this.PostageRefill_NAV,
      this.MakeAPayment_NAV,
      this.OnlineSupport_NAV
    ];
    if (this.localStorageService.merchantInfo.isSubscribed) {
      data.splice(1, 0, this.SubscriptionHistory_NAV);
    }
    return data;
  }

  /** Provide Data for dashboard Navigation module in accoutn with LOC account*/
  getNavOnlineSupportElement() {
    let data = [
      this.OnlineSupport_NAV
    ];
    return data;
  }

  /** Provide Data for dashboard Navigation module when no carrier setup, ups or fedex setup*/
  getNavCarrierSetupElements() {
    let data = [
      this.OnlineSupport_NAV
    ];
    if (this.localStorageService.merchantInfo.isSubscribed) {
      data.splice(0, 0, this.SubscriptionHistory_NAV);
    }
    return data;
  }

  /** Provide Data for dashboard Navigation module in accoutn with CC account*/
  getNavCCElements() {
    let data = [
      this.PostageRefill_NAV,
      this.AchOnlineSupport_NAV,
      this.OnlineSupport_NAV
    ];
    if (this.localStorageService.merchantInfo.isSubscribed) {
      data.splice(1, 0, this.SubscriptionHistory_NAV);
    }
    return data;
  }

  /**
   * Gets the get Delivery Guarantee details
   */
  getDeliveryGuarantee() {
    return this.http.get('/user/deliveryGuarantee').pipe(map(res => res));
  }

  /** Provide Data of banners for notifiaction panel on dashboard*/
  getBanners(merchantInfo) {
    // check visibilty of ach banner
    this.Banners[0].isVisible = this.achBannerVisiblility(merchantInfo);

    let filteredBanners = this.Banners.filter(function (banner) {
      return banner.isVisible;
    });

    return filteredBanners;
  }

  /** This function handles the visibility of ach banner, If merchant has ach account & is default merchant then this banner will be hidden from notifaiction panel*/
  achBannerVisiblility(merchantInfo) {
    if (!isNullOrUndefined(merchantInfo)
      && ((!isNullOrUndefined(merchantInfo.paymentMethod) &&
        (merchantInfo.paymentMethod.toUpperCase() === Constants.PAYMENT_METHOD_ACH || merchantInfo.isPostPay))
        || merchantInfo.isDefaultMerchant)) {
      return false;
    } else {
      return true;
    }
  }


  /**
   * This method is used to get the Login url.
   * @param {string} returnUrl
   * @returns {Observable<any>}
   */
  getTransactionAnalyticsData(dateFrom, dateTo): Observable<any> {
    let url = '/user/transactionAnalytics?dateFrom=' + dateFrom + '&dateTo=' + dateTo;
    return this.http.get(url);
  }

}
