<div class="mt-2">
    <p id="mfa-info-para">
        {{'MFA.MFA_PROCESS.MFA_PROCESS_MESSAGE' | translate}}
    </p>
</div>
<div>
    <p class="mb-1 mt-3" id="mfa-info-method">
        {{'MFA.MFA_PROCESS.MFA_AVAILABLE_METHODS' | translate}}
    </p>
    <hr class="mt-0">
    <div class="row mt-1 mb-3" *ngFor="let mfaDetails of mainMFADetailsMap | keyvalue">
        <div class="col-12 pr-3">
            <div class="card card-default rounded border-0 h-100 box-shadow">
                <div class="card-header" id="mfa-info-{{mfaDetails.key.slice(0,2)}}-header">
                    {{mfaDetails.key}}
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-1">
                            <i class="pbi-icon-outline x3" [ngClass]="getSideImage(mfaDetails.key)"
                                id="mfa-info-{{mfaDetails.key.slice(0,2)}}-icon"></i>
                        </div>
                        <div class="col-11 align-self-center" id="mfa-info-{{mfaDetails.key.slice(0,2)}}-value">
                            {{mfaDetails.value}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>