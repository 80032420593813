import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {isNullOrUndefined} from "util";

@Injectable()
export class RoutesInterceptor implements CanActivate {

    public loggedIn: boolean;


    constructor(private http: HttpClient, private router: Router) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if(!this.loggedIn) {
            let loggedInStr: string = localStorage.getItem('loggedIn');
            if (!isNullOrUndefined(loggedInStr)){
                this.loggedIn = JSON.parse(loggedInStr);
            } else {
                this.loggedIn = false;
            }
            if(this.loggedIn != true){
                this.redirectToLogin(state.url);
            }
        }
        return this.loggedIn;
    }

    redirectToLogin(url: string) {
        this.router.navigate(['login'], {queryParams: {returnUrl: url}});
    }
}
