import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from "../../services/language.service";
import { LocalStorageService } from '@services/local-storage.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html'
})
export class LanguageSelectorComponent implements OnInit {
  public languageList;
  public languageSelected;
  constructor(public translate: TranslateService, public languageService: LanguageService,
    public localStorageService: LocalStorageService) {
    translate.addLangs(Array.from(this.languageService.getLanguageList().values()));
    translate.setDefaultLang(this.languageService.getLanguageList().get('English-US'));
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/English | French/) ? browserLang : this.languageService.getLanguageSelected());
  }

  ngOnInit() {
    this.languageList = this.languageService.getLanguageList();
    this.languageSelected = this.languageService.getLanguageSelected();
  }

  onLanguageChange(language){
    this.languageService.setLanguageSelected(language);
  }

}