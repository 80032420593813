import {Injectable} from '@angular/core';
import {MerchantInfo} from "../models/MerchantDetails";
import {Constants} from "../utils/constants";
import {MerchantRegistrationDetails} from "../models/MerchantRegistrationDetails";
import {MerchantDetails} from "@models/MerchantDetails";
import {isNullOrUndefined} from "util";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    private _merchantInfo: MerchantInfo;
    private _userAccountDetails: MerchantInfo[];
    private _merchantDetails: MerchantDetails;
    private _merchantRegistrationDetails: MerchantRegistrationDetails;
    private _clientlogging: string;
    private _selectedAccountDevId: string;
    private _isAccSelected: string;
    private _clickedSwitchAccount: string;
    private _showBrowserSupport: string;
    private _mfaEnabled: string;

    constructor() {
    }

    get clientlogging(): string {
        return localStorage.getItem(Constants.CLIENT_LOGGING);
    }

    set clientlogging(value: string) {
        this._clientlogging = value;        
        localStorage.setItem(Constants.CLIENT_LOGGING, this._clientlogging);
    }

    get mfaEnabled(): string {
        return localStorage.getItem(Constants.MFA_ENABLED);
    }

    set mfaEnabled(value: string) {
        this._mfaEnabled = value;        
        localStorage.setItem(Constants.MFA_ENABLED, this._mfaEnabled);
    }

    get merchantInfo(): MerchantInfo {
        let merchantInfoObjStr = localStorage.getItem(Constants.MERCHANT_INFO);
        if (!isNullOrUndefined(merchantInfoObjStr) && merchantInfoObjStr != '' && merchantInfoObjStr != 'undefined' && merchantInfoObjStr != 'null') {
            this._merchantInfo = JSON.parse(merchantInfoObjStr);
            return this._merchantInfo;
        } else {
            return null;
        }
    }

    set merchantInfo(merchantInfo: MerchantInfo) {
        this._merchantInfo = merchantInfo;
        if (!isNullOrUndefined(merchantInfo)) {
            localStorage.setItem(Constants.MERCHANT_INFO, JSON.stringify(this._merchantInfo));
        } else {
            let nullString : string;
            localStorage.setItem(Constants.MERCHANT_INFO, nullString);
        }
        this._merchantInfo = this.merchantInfo
    }

    get MerchantDetails(): MerchantDetails {
        let merchantDetailsObjStr = localStorage.getItem(Constants.MERCHANT_DETAILS);
        if (merchantDetailsObjStr) {
            this._merchantDetails = JSON.parse(merchantDetailsObjStr);
        }
        return this._merchantDetails;
    }

    set MerchantDetails(merchantDetails: MerchantDetails) {
        if (merchantDetails) {
            this._merchantDetails = merchantDetails;
            localStorage.setItem(Constants.MERCHANT_DETAILS, JSON.stringify(this._merchantDetails));
        }
        this._merchantDetails = this._merchantDetails;
    }

    get selectedAccountDevId(): string {
        return localStorage.getItem(Constants.SELECTED_ACCOUNT_DEV_ID);
    }

    set selectedAccountDevId(value: string) {
        this._selectedAccountDevId = value;
        console.log("selectedAccountDevId - ",this._selectedAccountDevId);
        localStorage.setItem(Constants.SELECTED_ACCOUNT_DEV_ID, this._selectedAccountDevId);
    }

    get isAccountSelected(): string {
        return localStorage.getItem(Constants.ACC_SELECTED);
    }

    set isAccountSelected(value: string) {
        this._isAccSelected = value;
        console.log("isAccountSelected - ",this._isAccSelected);
        localStorage.setItem(Constants.ACC_SELECTED, this._isAccSelected);
    }


    get clickedSwitchAccount(): string {
        return localStorage.getItem(Constants.CLICKED_SWITCHED_ACCOUNT);
    }

    set clickedSwitchAccount(value: string) {
        this._clickedSwitchAccount = value;
        console.log("clickedSwitchAccount - ",this._clickedSwitchAccount);
        localStorage.setItem(Constants.CLICKED_SWITCHED_ACCOUNT, this._clickedSwitchAccount);
    }

    get showBrowserSupport(): string {
        return localStorage.getItem(Constants.SHOW_BROWSER_SUPPORT);
    }

    set showBrowserSupport(value: string) {
        this._showBrowserSupport = value;
        localStorage.setItem(Constants.SHOW_BROWSER_SUPPORT, this._showBrowserSupport);
    }

    set userAccountDetails(merchantInfoArray: MerchantInfo[]) {
        this._userAccountDetails = merchantInfoArray;
        localStorage.setItem(Constants.USER_ACCOUNT_DETAILS, JSON.stringify(this._userAccountDetails));
    }

    get userAccountDetails(): MerchantInfo[] {
        let userAccountDetails = localStorage.getItem(Constants.USER_ACCOUNT_DETAILS);
        if (userAccountDetails) {
            this._userAccountDetails = JSON.parse(userAccountDetails);
        }
        return this._userAccountDetails;

    }
}
