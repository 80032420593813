import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asteriskToSymbolSpacer'
})
export class AsteriskToSymbolSpacerPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value = value.replace(/.(?=.{4,}$)/g, '*');
    var str = value.replace(/\*/g, '●');
    var newstr = str.toString().match(/.{4}/g).join(' ');
    return newstr;
  }

}
