export class Constants {
    // sets an idle timeout of 900 seconds(15 min)
    public static readonly IDEAL_TIME = 3600;

    // sets a timeout period of 5 seconds.
    public static readonly TIME_OUT = 5;

    // sets the ping interval to 15 seconds
    public static readonly KEEP_ALIVE_PING_INTERVAL = 15;

    //The support email
    public static readonly SUPPORT_EMAIL = 'paymentsAPISupport@pb.com';

    //The support phone number
    // public static readonly SUPPORT_PHONE = '855-393-8874';
    // Payment Method Constants
    public static readonly URLPARAM_PAYMENT_METHOD_PP = 'PurchasePowerOnly';
    public static readonly URLPARAM_PAYMENT_METHOD_CC = 'CreditCardOnly';
    public static readonly URLPARAM_PAYMENT_METHOD_CC_LOC = 'CreditCard_LOC';
    public static readonly URLPARAM_PAYMENT_METHOD_ACH = 'ACHOnly';
    public static readonly URLPARAM_PAYMENT_METHOD_CC_ACH = 'CreditCard_ACH';
    public static readonly URLPARAM_PAYMENT_METHOD_LOC_ACH = 'LOC_ACH';
    public static readonly PAYMENT_METHOD_CC = 'CC';
    public static readonly PURCHASE_POWER = 'purchasePower';
    public static readonly CREDIT_CARD = 'creditCard';
    public static readonly PAYMENT_METHOD_ACH = 'ACH';
    public static readonly PAYMENT_METHOD_LOC = 'LOC';
    public static readonly PAYMENT_ALL = 'paymentAll';
    public static readonly RENDER_TYPE_CC = 'credit_card';
    public static readonly RENDER_TYPE_CC_ACH = 'cc_ach';
    public static readonly RENDER_TYPE_ACH = 'ach_only';
    public static readonly ACCOUNT_PAYPAL = 'PYPL';
    public static readonly PAYMENTS_FIELD_NAME = 'payments';

    //The supported Payment Methods
    public static readonly SUPPORTED_PAYMENT_METHODS = [Constants.URLPARAM_PAYMENT_METHOD_PP,
    Constants.URLPARAM_PAYMENT_METHOD_CC, Constants.URLPARAM_PAYMENT_METHOD_CC_LOC,
    Constants.URLPARAM_PAYMENT_METHOD_ACH, Constants.URLPARAM_PAYMENT_METHOD_CC_ACH,
    Constants.URLPARAM_PAYMENT_METHOD_LOC_ACH
    ];
    public static readonly POSTPAY_PAYMENT_METHODS = "POSTPAY";

    //constants added for adobe analytics
    public static readonly MERCHANT_PORTAL = 'merchantportal';
    public static readonly DASHBOARD = 'dashboard';
    public static readonly US = 'us';
    public static readonly EN = 'en';
    public static readonly LOGGED_IN = 'Logged In';
    public static readonly NOT_LOGGED_IN = 'Not Logged In';
    public static readonly PIPE = '|';
    public static readonly BILLING_PAYMENTS = 'billing';
    public static readonly ACCOUNT_INFORMATION = 'accountInformation';
    public static readonly FAQ = 'faq';
    public static readonly SUCCESS = 'success';
    public static readonly PAYMENT_METHOD = 'billing | payment method';
    public static readonly MERCHANT_TRANSACTIONS = 'transaction';
    public static readonly MERCHANT_PARCEL_PROTECTION = 'Parcel Protection ';
    public static readonly POSTAGE_REFILLS = 'billing | postage refills';
    public static readonly ACCOUNT_INFO = 'accountInfo';
    public static readonly REGISTERED = 'registered';
    public static readonly NOT_REGISTERED = 'not-registered';
    public static readonly REGISTRATION_ERROR = 'registrationError';
    public static readonly LANDING_PAGE = 'landingPage';
    public static readonly ADDRESS_VERIFY_PAGE = 'addressVerification';
    public static readonly SUBSCRIPTION_DETAILS = 'billing | subscription history';
    public static readonly USPS_PAYMENT_SETUP = 'uspsPaymentSetup';

    // local storage service
    public static readonly MERCHANT_INFO = 'merchantInfo';
    public static readonly MERCHANT_DETAILS = 'merchantDetails';
    public static readonly SELECTED_ACCOUNT_DEV_ID = 'selectedAccountDevId';
    public static readonly ACC_SELECTED = 'accountSelected';
    public static readonly CLICKED_SWITCHED_ACCOUNT = 'clickedSwitchAccount';
    public static readonly CLIENT_LOGGING = 'client_logging';
    public static readonly SHOW_BROWSER_SUPPORT = 'showBrowserSupport';
    public static readonly MFA_ENABLED = 'mfa_enabled';
    public static readonly USER_ACCOUNT_DETAILS = "userAccountDetails";
    // parcel transactions export
    public static readonly CSV_EXPORT = 'exportCSV';
    public static readonly SHIPPING_PROGRAM_V1 = 'SHIPPING_PROGRAM_v1';

    // Errors and Warnings
    public static readonly GENERIC_ERROR = 'Something went wrong, please try again.';
    public static readonly SETUP_WARNING = 'You must complete your Account Setup before you can view payment information';
    public static readonly HTTP_401_UNAUTHORISED_RESP_CODE = 401;
    public static readonly HTTP_403_FORBIDDEN_RESP_CODE = 403;
    public static readonly HTTP_417_Expectation_Failed_CODE = 417;
    public static readonly USER_Found = 'user found';
    public static readonly STATUS = 'status';
    public static readonly MERCHANT_STATUS_SUSPENDED = 'SUSPENDED';
    public static readonly INVALID_TXN_DATE_TIME_ERR_CODE = 'invalid.date.time';
    public static readonly INVALID_TXN_DATE_TIME_ERROR = 'Date range cannot exceed 3 months.';
    public static readonly POSTAGE_REFILL_TXN_ERROR = 'Getting postage refill transactions failed.';
    public static readonly GENERIC_TXN_ERROR = 'Error occurred while loading data. Please try again.';

    // URLs
    public static readonly CREATE_REGISTRATION_ERROR_ROUTE = 'create/registrationError';
    public static readonly CREATE_SUCCESS_ROUTE = 'create/success';
    public static readonly CREATE_PAYMENT_INFO_ROUTE = 'create/paymentInfo';
    public static readonly CREATE_ACCOUNT_INFO_ROUTE = 'create/accountInfo';
    public static readonly CREATE_VERIFY_ADDRESS_ROUTE = 'create/addressVerification';
    public static readonly CREATE_REGISTER_MFA_ROUTE = 'auth/register-mfa';
    public static readonly FRAUD_CHECK_MERCHANT_STATUS = 'merchantStatus';
    public static readonly DASHBOARD_ROUTE = '/dashboard';
    public static readonly SUBSCRIPTION_PAYMENT_ROUTE = '/carrier-setup/subscription-payment';
    public static readonly CAN_NOT_PROCESS_REQUEST_ROUTE = 'canNotProcessRequest';
    public static readonly USPS_SETUP_ROUTE = '/carrier-setup/usps';
    public static readonly CONTACT_SUPPORT_TEAM = 'contactSupportTeam';
    public static readonly ONLINE_SUPPORT_ACH_ROUTE = '/online-support/content/ach-electronic-bank-account-transfer';

    // Geo Search API Req Params
    public static readonly COUNTRY = 'USA';
    public static readonly MAXCANDIDATES = '10';
    public static readonly MATCHONADDRESSNUMBER = 'false';

    // MultiCarrier_Constants
    public static readonly USPS = 'USPS';
    public static readonly PBSTANDARD = 'PBSTANDARD';
    public static readonly FEDEX = 'FEDEX';
    public static readonly UPS = 'UPS';
    public static readonly POSTAGE_ACCOUNT_TYPE = 'postage';
    public static readonly SUBSCRIPTION_ACCOUNT_TYPE = 'subscription';
    public static readonly POSTAGE_N_SUBSCRIPTION_ACC_TYPE = 'postageAndSubscription';
    public static readonly PAY_FOR_POSTAGE_N_SUBSCRIPTION = 'To pay for postage and subscription';
    public static readonly PAY_FOR_POSTAGE = 'To pay for postage';
    public static readonly PAY_FOR_SUBSCRIPTION = 'To pay for subscription';
    public static readonly US_STATELIST = ["AK", "AL", "AR", "AS", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "GU", "HI", "IA", "ID",
        "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MH", "MI", "MN", "MO", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY",
        "OH", "OK", "OR", "PA", "PR", "PW", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VI", "VT", "WA", "WI", "WV", "WY"];
    public static readonly CA_STATELIST = ["BC", "MB", "NB", "NL", "NT", "NS", "NU", "ON", "PE", "QC", "SK", "YT"];

    // Multi Carrier Success & Error Message
    public static readonly ERROR_USPS_ACCOUNT_BALANCE = 'Fetching account balance failed';
    public static readonly ERROR_LICENSE = 'error';
    public static readonly ERROR_CANT_PROCEED = 'We can\'t process your request. Wait a moment and try again. If the issue persists, contact PB support.'
    public static readonly CARRIER_SUCCESS = '{0} is successfully added. You are ready to start shipping.';
    public static readonly ADDITIONAL_CARRIER_SETUP = ' To add additional carriers, go to Shipping Carriers in the main menu.';
    public static readonly LICENSE_AGREEMENT_ERROR = 'An error has occurred while fetching license agreement.';
    public static readonly INITIAL_ADD_FUND_ERROR = 'An error has occurred while adding initial postage balance. Please try to add fund in a moment.';

    // Postage refill HTML validation
    public static readonly REFILL_AMOUNT_REQUIRED = 'Enter a valid refill amount.';
    public static readonly REFILL_AMOUNT_MIN_VALUE = 'Refill amount should be greater than 50.';
    public static readonly REFILL_AMOUNT_MAX_LENGTH = 'Refill amount should be maximum of 8 digits.';
    public static readonly THRESHOLD_AMOUNT_REQUIRED = 'Enter a valid threshold amount.';
    public static readonly THRESHOLD_AMOUNT_MIN_VALUE = 'Threshold amount must be at least 20% of the refill amount.';
    public static readonly THRESHOLD_AMOUNT_MAX_VALUE = 'Threshold amount should be lesser than refill amount.';

    // UPS and FedEx API Request Params
    public static readonly CLIENT_SOFTWARE_PRODUCT = 'CLIENT_SOFTWARE_PRODUCT';
    public static readonly ACCOUNT_POSTAL_CODE = 'ACCOUNT_POSTAL_CODE';
    public static readonly ACCOUNT_COUNTRY_CODE = 'ACCOUNT_COUNTRY_CODE';
    public static readonly CONTACT_TITLE = 'CONTACT_TITLE';
    public static readonly END_USER_IP = 'END_USER_IP';
    public static readonly DEVICE_IDENTITY = 'DEVICE_IDENTITY';
    public static readonly LICENSE_TEXT = 'LICENSE_TEXT';
    public static readonly INVOICE_AMOUNT = 'INVOICE_AMOUNT';
    public static readonly INVOICE_CONTROL_ID = 'INVOICE_CONTROL_ID';
    public static readonly INVOICE_DATE = 'INVOICE_DATE';
    public static readonly INVOICE_NUMBER = 'INVOICE_NUMBER';
    public static readonly INVOICE_CURRENCY_CODE = 'INVOICE_CURRENCY_CODE';
    public static readonly CURRENCY_CODE_US = 'USD';
    public static readonly CURRENCY_CODE_CA = 'CAD';

    // UPS & FedEx API Error Codes
    public static readonly DUPLICATE_ACCOUNT_NUMBER_ERROR_CODE = 4000005;
    public static readonly RETRY_WITH_CC_ERROR_CODE = 4040009;

    // ACH
    public static readonly ACH_INFO = 'Postage refills using ACH will be processed immediately in most of the scenarios.';
    public static readonly ACH_RETURN_REASON = 'ACH Return';

    // Delivery Gurranty
    public static readonly PB_DG_CLAIM_EMAIL = 'PBDClaims@pb.com';
    public static readonly DELIVERY_GUARANTY_URL = 'https://www.pitneybowes.com/us/ecommerce-delivery-services/deliveryguarantee.html';
    public static readonly DELIVERY_GUARANTY_TnC_URL = 'https://www.pitneybowes.com/us/license-terms-of-use/shipping-api-merchant-terms-of-use.html';

    //PB STANDARD
    public static readonly PB_STANDARD_TECH_SUPPORT_EMAIL= 'clientsupporttechservices@pb.com';
    public static readonly PB_STANDARD_TECH_SUPPORT_DOC_URL = 'https://www.pitneybowes.com/us/ecommerce-delivery-services/contact-us-delivery.html';


    // LOC
    public static readonly ACCOUNT_PP = 'PP';
    public static readonly ACCOUNT_RA = 'RA';
    public static readonly LINKBANKACCOUNT = 'linkBankAccount';
    public static readonly PAID = 'paid';

    // LOC
    public static readonly EXPORT_TYPE_CSV = 'CSV';
    public static readonly EXPORT_TYPE_EXCEL = 'EXCEL';

    // Multi-factor Authentication
    public static readonly MFA_SMS_HEADING = 'SMS Authentication';
    public static readonly MFA_VOICE_CALL_HEADING = 'Voice Call Authentication';
    public static readonly MFA_GOOGLE_AUTH_HEADING = "Google Authenticator";
    public static readonly MFA_OKTA_VERIFY_HEADING = "Okta Verify Authentication";
    public static readonly MFA_RSA_HEADING = "RSA Authentication";
    public static readonly MFA_SECURITY_QUESTION_HEADING = "Security Question Authentication";
    public static readonly MFA_EMAIL_HEADING = "Email Authentication";

    // ZipCode Regex
    public static readonly US_REGEX_ZIPCODE = /^[0-9]{5}(-[0-9]{4})?$/;
    public static readonly CA_REGEX_ZIPCODE = '^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$';

    // Conuntry Codes 
    public static readonly US_COUNTRY_CODE = 'US';
    public static readonly CA_COUNTRY_CODE = 'CA';

    public static readonly ANALYTICAL_DASHBOARD = "Analytical Dashobard";
    public static readonly SWITCH_ACCOUNT_ROUTE = "/switchAccount";
    public static readonly FAILURE = "FAILURE";

    // FDR Constants
    public static readonly CONTAINER_TYPE_LIST = [{ "key": "Carton", "value": "Carton" }, { "key": "Pallet", "value": "Pallet" },
    { "key": "Gaylord", "value": "Gaylord" }, { "key": "Carton", "value": "License Plate #" }, { "key": "Truck", "value": "Truck" }]
    public static readonly FACILITY_NAME_LIST = [{ "key": "0224", "value": "ATL" }, { "key": "2035", "value": "ATL4" },
    { "key": "2109", "value": "BWI" }, { "key": "1304", "value": "COM" }, { "key": "0921", "value": "CVG" },
    { "key": "1361", "value": "DFW" }, { "key": "1779", "value": "EWR" }, { "key": "1585", "value": "IND" },
    { "key": "2121", "value": "MCO" }, { "key": "1787", "value": "ONT" }, { "key": "1094", "value": "ORD" },
    { "key": "1532", "value": "PHL" }, { "key": "1328", "value": "RNO" }, { "key": "2124", "value": "SCK" },
    { "key": "1571", "value": "SEA" }, { "key": "1548", "value": "SFO" }, { "key": "1712", "value": "SLC" }];
    public static readonly LABEL_SIZE_LIST = [{ "key": "DOC_4X4", "value": "4X4" }, { "key": "DOC_6X4", "value": "6X4" }];
    public static readonly RESOLUTION_LIST = ['DPI_203', 'DPI_300'];
    public static readonly ERROR_INVALID_CSV = 'Please upload a valid CSV file and file should contain one parcel tracking number in each row.';
    public static readonly ERROR_INVALID_LABEL = 'Error occurred while generating the container label.';
    public static readonly ERROR_INVALID_PTN = 'Invalid Parcel Tracking Number';
    public static readonly ERROR_INVALID_PTN_INFO = 'Parcel tracking number should be of type numeric.';
    public static readonly ERROR_INVALID_PTN_EXP_INFO = 'Parcel tracking number should not be in exponential format.';
    public static readonly ERROR_EMPTY_PTN_INFO = 'Parcel tracking number cannot be empty.';
    public static readonly ERROR_INVALID_ROW = 'Invalid CSV File';
    public static readonly ERROR_MAX_PTN = 'Limit Exceeds';
    public static readonly ERROR_MAX_PTN_INFO = 'Max 100k parcel tracking numbers are allowed.';
    public static readonly LABEL_SUCCESS = 'Container label generated successfully.';
    public static readonly NEWGISTICS = 'Newgistics';
    public static readonly PDF = 'PDF';

    // Container Label API Request Params
    public static readonly CLIENT_ID = 'CLIENT_ID';
    public static readonly CURRENT_CONTAINER = 'CURRENT_CONTAINER';
    public static readonly TOTAL_CONTAINER_COUNT = 'TOTAL_CONTAINER_COUNT';
    public static readonly SHIPMENT_REFERENCE_NUMBER = 'SHIPMENT_REFERENCE_NUMBER';
    public static readonly CARRIER_GATEWAY_FACILITY_ID = 'CARRIER_GATEWAY_FACILITY_ID';
    public static readonly CARRIER_FACILITY_ID = 'CARRIER_FACILITY_ID';
    public static readonly CLIENT_CONTAINER_ID = 'CLIENT_CONTAINER_ID';
    public static readonly SHIP_DATE = 'SHIP_DATE';
    public static readonly ESTIMATED_ARRIVAL_DATE = 'ESTIMATED_ARRIVAL_DATE';
    public static readonly HANDLING_INSTRUCTIONS = 'PRINT_CUSTOM_MESSAGE_1';
    public static readonly PACKAGE_COUNT = 'PACKAGE_COUNT';

    // Credit Card Types
    public static readonly CC_TYPE_AMEX = 'AMEX';
    public static readonly CC_TYPE_MASTER = 'MASTER';
    public static readonly CC_TYPE_VISA = 'VISA';
    public static readonly CC_TYPE_DISCOVER = 'DISCOVER';
    public static readonly CC_TYPE_MC = 'MC';
    public static readonly CC_TYPE_DISC = 'DISC';

    //USER PREFERENCES 
    public static readonly TIME_ZONE_UPDATE_FAILED = "Error occurred while updating Time Zone";
    public static readonly TIME_ZONE_UPDATE_SUCCESSFUL = "Time Zone preference updated successfully";
    public static readonly DATE_FORMAT_UPDATE_FAILED = "Error occurred while updating Date Format";
    public static readonly DATE_FORMAT_UPDATE_SUCCESSFUL = "Date Format preference updated successfully";
    public static readonly DATE_FORMAT_MEDIUM = "MMM DD, YYYY, h:mm:ss a";
    public static readonly DATE_FORMAT_SHORT = "MM/DD/YY, h:mm:ss a";
    public static readonly DATE_FORMAT_OPTIONS = [Constants.DATE_FORMAT_MEDIUM, Constants.DATE_FORMAT_SHORT];

    public static readonly IDENTITY_VERIFICATION_FAILED = 'We cannot verify your identity. Please try again in a moment or If the issue persists please contact support at clientsupporttechservices@pb.com';
}
