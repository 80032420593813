import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { isNullOrUndefined } from "util";
import { map } from "rxjs/operators";
import { MerchantDetails, MerchantInfo } from "../models/MerchantDetails";
import { LocalStorageService } from "./local-storage.service";
import { MerchantStatus } from "@models/MerchantStatus";

@Injectable({
    providedIn: 'root'
})
export class MerchantDetailsService {

    constructor(private http: HttpClient, private localStorageService: LocalStorageService, ) {
    }

    getMerchantInfo() {
        let userInfo = localStorage.getItem('userInfo');
        let userName = localStorage.getItem('username');

        if (!isNullOrUndefined(userInfo)) {
            return userInfo;
        }
        else {
            return this.http.get<MerchantDetails>('/user/info?email=' + userName)
                .pipe(
                    map(res => res)
                );
        }
    }

    /**
     *Used to validate if need to show the switch account pop and have to fetch the multiple account details or not.
     * To handle the SwitchAccounts we have to meet 2 conditions .merchantAccountCount > 1 and isRenderedFromIframe === false
     * @returns {boolean}
     */
    isRequiredToHandleSwitchAccounts() {
        let merchantInfo: MerchantInfo = this.localStorageService.merchantInfo;
        if (merchantInfo && Number(merchantInfo.merchantAccountCount) && Number(merchantInfo.merchantAccountCount) > 1 &&
            !isNullOrUndefined(merchantInfo.isRenderedFromIframe) && Boolean(merchantInfo.isRenderedFromIframe) === false) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Used to get the multiple account associated with merchant.
     * @returns {Observable<any>}
     */
    getMerchantStatus<MerchantStatus>() {
        return this.http.get<MerchantStatus>('/api/v1/merchantStatus')
            .pipe(
                map(res => res)
            );
    }

    getMerchantPaymentInfo() {
        return this.http.get('/api/v1/payment/info')
            .pipe(
                map(res => res)
            );
    }

    // get user enterpriseId by bank list
    getBanksDetails() {
        return this.http.get('/api/v1/e1/getBankAccounts')
            .pipe(
                map(res => res)
            );
    };

    //link bank account
    linkBankAccount = function (requestBody) {
        return this.http.post('/api/v1/e1/createAchProfile', requestBody)
            .pipe(
                map(res => res)
            );
    };

    // make payments
    makePaymentDetails = function (requestBody) {
        return this.http.post('/api/v1/e1/payBalance', requestBody)
            .pipe(
                map(res => res)
            );
    };
}