import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { DashboardService } from "../dashboard/dashboardService/dashboard.service";
import { MerchantInfo } from "@models/MerchantDetails";
import { Constants } from "@utils/constants";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@services/auth.service";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { CarrierSetupService } from './carrier-setup.service';


@Injectable({
    providedIn: 'root'
})
export class UtilService {

    public billingPaymentsStatus = {
        isNoCarrierSetup: true,
        isUSPSCarrierSetup: false
    }
    public isUpsProfileConfigured;
   

    constructor(private localStorageService: LocalStorageService,private carrierSetupService: CarrierSetupService, private dashboardService: DashboardService, private router: Router, private http: HttpClient) {
    }

    processErrorResponse(errorResp) {
        let errorString = '';
        if (errorResp.error && errorResp.error.errors) {
            errorResp.error.errors.forEach(function (errorDetails) {
                if (errorString != '') {
                    errorString = errorString + '\n';
                }
                errorString = errorString + errorDetails.errorDescription;
            });
        }
        if (errorString == '') {
            errorString = null;
        }
        return errorString;
    }

    /*
     * This function is used to get the carrierDetails and set isNoCarrierSetup and isUSPSCarrierSetup accordingly.
     */
    setCarrierDetails(): Observable<any> {
        return new Observable(obs => {
            
            this.dashboardService.getUserDetails().subscribe((merchantInfoObj: MerchantInfo) => {
                let carrierDetails = merchantInfoObj.carrierDetails;
                this.isUpsProfileConfigured = this.isUpsProfileConfigured_();
                console.log("this.isUpsProfileConfigured util" ,this.isUpsProfileConfigured );
                if(this.isUpsProfileConfigured==true){
                carrierDetails.isUPSCarrierRegistered === true;
                }
                if (carrierDetails && (carrierDetails.isUPSCarrierRegistered === true
                    || carrierDetails.isUSPSCarrierRegistered === true
                    || carrierDetails.isFedExCarrierRegistered === true)) {
                    this.billingPaymentsStatus.isNoCarrierSetup = false;
                } else {
                    this.billingPaymentsStatus.isNoCarrierSetup = true;
                }
                if (carrierDetails && carrierDetails.isUSPSCarrierRegistered === true) {
                    this.billingPaymentsStatus.isUSPSCarrierSetup = true;
                } else {
                    this.billingPaymentsStatus.isUSPSCarrierSetup = false;
                }
                obs.next(this.billingPaymentsStatus);
                obs.complete();
                return { success: true };
            }, (error) => {
                localStorage.clear();
                sessionStorage.clear();
                this.router.navigate(['loginError']);
            });
        });
    }
    isUpsProfileConfigured_(){
        this.carrierSetupService.isUpsProfileConfigured().subscribe(resp=>{
          if(resp == true){
          this.isUpsProfileConfigured=true;
          }
          return resp;
        },error =>{
          this.router.navigate([Constants.CAN_NOT_PROCESS_REQUEST_ROUTE]);
        });
      }

    /**
     * This function is used to get the developer portal url.
     */
    getDevPortalUrl() {
        return this.http.get('/api/v1/devPortalUrl')
            .pipe(
                map(res => res)
            );
    }

    getNSAPortalUrl() {
        return this.http.get('/api/v1/nsaPortalUrl')
            .pipe(
                map(res => res)
            );
    }

}
