<ng-container>
    <!-- ACCOUNT SWITCHER menu -->
    <li class="nav-item dropdown" ngbDropdown #acctSwitcher="ngbDropdown">
        <a class="nav-link cursor-pointer" aria-label="Accounts" aria-haspopup="true" aria-expanded="false"
            ngbDropdownToggle>
            <i class="pbi-icon-outline pbi-account-switcher" aria-hidden="true"></i>
            <span
                class="d-inline-block ml-2 account-display">{{selectedAccount.developerCompanyName && selectedAccount.developerCompanyName != '' ? selectedAccount.developerCompanyName : selectedAccount.developerName}}</span>
        </a>
        <div class="dropdown-menu" role="menu" ngbDropdownMenu>

            <a class="dropdown-item" (click)="switchAccount('all')">See Details</a>
            <div class="dropdown-divider"> </div>
            <ng-container *ngFor="let merchantInfo of switchingAccounts">
                <a class="dropdown-item"
                    (click)="switchAccount(merchantInfo.developerId)">{{merchantInfo.developerCompanyName && merchantInfo.developerCompanyName != '' ? merchantInfo.developerCompanyName : merchantInfo.developerName}}</a>
            </ng-container>
        </div>
    </li>
</ng-container>