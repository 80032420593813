import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private http: HttpClient) {
    }

    /**
     * This method is used to get the Login url.
     * @param {string} returnUrl
     * @returns {Observable<any>}
     */
    getLoginURL(returnUrl: string) {
        return this.http.get('/auth/loginUrl', {params: {returnUrl: returnUrl}})
            .pipe(
                map(res => res)
            );
    }

    /**
     * This method is used for logout purpose. It returns the logout url and clears the express-session.
     * @returns {Observable<any>}
     */
    getLogoutUrl() {
        return this.http.get('/auth/logout')
            .pipe(
                map(res => res)
            );
    }

    /**
     * Used to invalidate token. So that it can't be used again.
     * @param token 
     */
    invalidateAccessToken(accessToken){
        return this.http.post('/auth/invalidateToken', {'accessToken': accessToken})
            .pipe(
                map(res => res)
            );
    }

    /**
     * Used to check if the loged in merchant has allready configured the MFA or not.
     * @param token 
     */
    getUserMFAGroupMembership(): any{
        return this.http.get('/auth/userMFAGroupMembership')
        .pipe(
            map(res => res)
        );
    }

    /**
     * Used to check if the loged in merchant has allready configured the MFA or not.
     * @param token 
     */
    resetMFAForMerchant(): any{
        return this.http.post('/auth/resetMultiFactor', {})
        .pipe(
            map(res => res)
        );
    }

    /**
     * Used to check if the loged in merchant has allready configured the MFA or not.
     * @param token 
     */
    enableMFAForMerchant(): any{
        return this.http.put('/auth/enableMultiFactor', {})
        .pipe(
            map(res => res)
        );
    }

    /**
     * Used to check if the loged in merchant has allready configured the MFA or not.
     * @param token 
     */
    removeMFAForMerchant(): any {
        return this.http.delete('/auth/removeMultiFactor')
        .pipe(
            map(res => res)
        );
    }

    
}
