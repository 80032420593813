import { Component, OnInit, HostBinding } from '@angular/core';
import { ClientLogger } from "@services/client.logger.service";
import { LocalStorageService } from "@services/local-storage.service";
import { DashboardService } from "../../dashboard/dashboardService/dashboard.service";
import { MerchantInfo } from "@models/MerchantDetails";
import { Router } from "@angular/router";
import { Constants } from "@utils/constants";

@Component({
    selector: 'app-switch-account-header',
    templateUrl: './switch-account-header.component.html',
    styles: []
})
export class SwitchAccountHeaderComponent implements OnInit {
    @HostBinding('class.site-header') true;

    public selectedAccount: MerchantInfo;
    public switchingAccounts: MerchantInfo[];

    constructor(protected clientLogger: ClientLogger,
        private router: Router,
        protected localStorageService: LocalStorageService,
        protected dashboardService: DashboardService) {
        this.clientLogger.log("In SwitchAccountHeaderComponent constructor.");
        let merchantInfoArray = this.localStorageService.userAccountDetails;
        this.selectedAccount = merchantInfoArray.filter(merchant => merchant.developerId === this.localStorageService.selectedAccountDevId)[0];
        this.switchingAccounts = merchantInfoArray.filter(merchant => merchant.developerId !== this.localStorageService.selectedAccountDevId);
    }

    ngOnInit() {
        this.clientLogger.log("In SwitchAccountHeaderComponent ngOnInit.");

    }

    switchAccount(developerId) {
        if (developerId === 'all') {
            this.router.navigate([Constants.SWITCH_ACCOUNT_ROUTE]);
        } else {
            this.localStorageService.selectedAccountDevId = developerId;
            this.dashboardService.generateUserDetailsReq().subscribe((resp) => {
                //Has to reload since we have to refresh dashbaord, header and rest of components.
                window.location.reload();
            });
        }
    }
}
