import { Directive, HostBinding, Self, DoCheck } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName], [ngModel], [formControl]',
})
export class FormControlValueDirective implements DoCheck {

  @HostBinding('class.is-empty') isEmpty;
  @HostBinding('class.is-value') isValue;

  constructor(@Self() private cd: NgControl) { }

  ngDoCheck() {
    this.isEmpty = this.cd.control.value ? false : true;
    this.isValue = this.cd.control.value ? true : false;
  }
}