import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute} from "@angular/router";
import {SessionService} from "../../services/session.service";

@Component({
    selector: 'app-logout',
    templateUrl: '../../utils/loadingScreen.html',
    styleUrls: []
})
export class LogoutComponent implements OnInit {

    private logoutUrl: string;

    constructor(private sessionService: SessionService) {
    }

    ngOnInit() {
        this.redirectToSaasLogout();
    }

    /**
     * Fetches SAAS E2E Logout URL and redirects to it and clears session
     */
    redirectToSaasLogout() {
        this.sessionService.destroySession();
    }
}
