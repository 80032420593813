<div class="modal-header">
    <h2 class="modal-title" id="add-postage-header">Add Postage</h2>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()" id="add-postage-modal-close">
        <span aria-hidden="true"></span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="refillPostageForm" autocomplete="off" novalidate>
        <p id="available-postage-info">Available Postage: {{postageInfo.postageBalance | currency}}</p>

        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label id="add-postage-amount-label">Add postage amount</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <input id="oneTimeRefillAmount" formControlName="refillAmount" type="number"
                            class="form-control col-5"
                            [ngClass]="{'is-invalid': refillAmount.invalid && refillAmount.touched}"
                            aria-label="Amount (to the nearest dollar)" min="0" required>
                        <div class="invalid-feedback" role="alert" *ngIf="refillAmount.invalid && refillAmount.touched">
                            <div *ngIf="refillAmount.hasError('required') || refillAmount.hasError('pattern')"
                                id="oneTimeRefillAmountValidationError">
                                <span class="text-danger">Postage amount is required and must be a number.</span>
                            </div>
                            <div *ngIf="refillAmount.hasError('min')" id="lowOneTimeRefillAmountValidationError">
                                <span class="text-danger">Must add at least $5.00.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>	
    </form>
</div>

<div class="modal-footer">
    <button  type="button" id="refillPostageSubmitBtn" class="btn btn-page-primary"
        (click)="onSubmit()">Submit</button>
    <button  type="button" id="refillPostageCancelBtn" class="btn btn-secondary"
        (click)="activeModal.close()">Cancel</button>
</div>

<p class="mt-3 mb-0 small" id="postage-refill-terms">By clicking Submit, you agree that your account will be billed the
    amount entered above.
</p>

