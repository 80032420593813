import {Component, HostBinding, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Constants} from "@utils/constants";

@Component({
  selector: 'app-can-not-process-request',
  templateUrl: './can-not-process-request.component.html'
})
export class CanNotProcessRequestComponent implements OnInit {

    public readonly supportEmail: string = Constants.SUPPORT_EMAIL;
    // public readonly supportPhone: string = Constants.SUPPORT_PHONE;


    @HostBinding('class.errors') true;

    constructor(public router: Router) { }

    ngOnInit() {}

}
