<footer>
    <div class="container-fluid">
        <div class="row align-items-center">

            <div class="col-xs-12 col-md-8 col-lg-6">
                <div class="footer-logo-copyright">
                    <a class="footer-logo" href="http://www.pb.com" aria-label="Pitney Bowes" id="footer-pb-logo"></a>
                    <span class="footer-copyright" id="footer-date">&copy;{{date}} Pitney Bowes Inc.
                    </span>
                </div>
            </div>

            <div class="col-xs-12 col-md-4 col-lg-6 text-center text-md-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a
                            href="https://www.pitneybowes.com/us/license-terms-of-use/shipping-api-merchant-terms-of-use.html"
                            target="_blank" rel="noopener noreferrer" id="footer-terms-conditions">Terms and conditions</a></li>
                    <li class="list-inline-item">
                        <app-contact-us></app-contact-us>
                    </li>
                    <li class="list-inline-item" hidden>
                        <app-language-selector></app-language-selector>
                    </li>
                    <li class="list-inline-item">
                        <a routerLink="/merchantPortalFAQ/cookie-policy" id="cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</footer>