import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from "@utils/constants";

@Component({
    selector: 'app-refill-postage',
    templateUrl: './refill-postage-modal.component.html'
})

export class RefillPostageModal implements OnInit {

    @Input() public postageInfo;
    public refillPostageForm: UntypedFormGroup;
    public achInfo = Constants.ACH_INFO;

    constructor(protected formBuilder: UntypedFormBuilder, public activeModal: NgbActiveModal) {

    }

    ngOnInit() {
        this.createForm();
    }

    /***
     * Create the postage refill form.
     */
    createForm() {
        this.refillPostageForm = this.formBuilder.group({
            refillAmount: ['', [Validators.required, Validators.pattern("^\\d+$"), Validators.min(5)]],
        });
    }

    get refillAmount() {
        return this.refillPostageForm.get('refillAmount');
    }

    /***
     * Validate and close the postage refill amount dialog with amount value.
     */
    onSubmit() {
        if (this.refillPostageForm.valid) {
            this.activeModal.close(this.refillAmount.value)
        } else {
            this.validateAllFields(this.refillPostageForm);
        }
    }

    /***
     * Validate the reactive form fields.
     */
    validateAllFields(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFields(control);
            }
        });
    }

}
