import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AuthService } from "../../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MerchantInfo } from "../../models/MerchantDetails";
import { DashboardService } from "../../dashboard/dashboardService/dashboard.service";
import { isNullOrUndefined } from "util";
import { Constants } from "../../utils/constants";
import { ClientLogger } from '@services/client.logger.service';
import { MonitoringUserActivityService } from "@services/monitoring.user.activity.service";
import { Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import {LocalStorageService} from "@services/local-storage.service";


@Component({
    selector: 'app-login',
    templateUrl: '../../utils/loadingScreen.html',
    styleUrls: []
})
export class LoginComponent implements OnInit {
    private returnUrl: string;
    private loginUrlResponse: string;

    @ViewChild('SessionExpireTemplate', { read: TemplateRef })
    private sessionExpireTemplate: TemplateRef<any>;

    constructor(private authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                public dashboardService: DashboardService,
                private clientLogger: ClientLogger,
                private moniteringUserActivityService: MonitoringUserActivityService,
                private idle: Idle,
                private keepalive: Keepalive,
                private localStorageService: LocalStorageService) {
    }

    ngOnInit() {
        // Read Query params
        this.route.queryParams.subscribe(params => {
            this.returnUrl = params['returnUrl'];
            if (isNullOrUndefined(this.returnUrl)) {
                this.returnUrl = '/dashboard';
            }
        });
        this.dashboardService.getUserDetails().subscribe((merchantInfoObj: MerchantInfo) => {
            if (merchantInfoObj.email && this.returnUrl) {
                localStorage.setItem('loggedIn', 'true');
                this.moniteringUserActivityService.setIdealSettings(this.idle, this.keepalive, this.sessionExpireTemplate);
                if(this.isRequiredToHandleSwitchAccounts()) {
                    //If user has mutiple accounts then fetch accounts details and store it in local storage since used frequently for switch accounts.
                    this.dashboardService.getUserAccountDetails().subscribe((merchantInfoArray: MerchantInfo[]) => {
                    this.localStorageService.userAccountDetails = merchantInfoArray;
                    this.router.navigate([Constants.SWITCH_ACCOUNT_ROUTE]);
                    });
                } else {
                    // Note: router.navigate encodes the query params in url instead of that we use router.navigateByUrl which Navigates to a view using an absolute route path.
                    this.router.navigateByUrl(this.returnUrl);
                }
            } else {
                this.redirectToSaasLogin();
            }
        }, (error) => {
            if (error.status === Constants.HTTP_403_FORBIDDEN_RESP_CODE) {
                this.redirectToSaasLogin();
            } else {
                localStorage.clear();
                sessionStorage.clear();
                if (error.error && error.error.errors && error.error.errors.length > 0 &&
                    (error.error.errors[0].errorCode == '5000020' || error.error.errors[0].errorCode == '5000021')) {
                    this.router.navigateByUrl('/loginError?errorMessage=We can’t process your request.');
                } else {
                    this.router.navigate(['loginError']);
                }

            }

        });
    }

    /**
     * Fetches SAAS E2E Login URL and redirects to it.
     */
    redirectToSaasLogin() {
        this.authService.getLoginURL(this.returnUrl).subscribe(
            response => {
                this.loginUrlResponse = response["loginURL"];
            },
            () => {
                this.clientLogger.log("error while fetching Login URL");
            }
            ,
            () => {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = this.loginUrlResponse;
            }
        );
    }


    /**
     *Used to validate if need to show the switch account pop and have to fetch the multiple account details or not.
     * To handle the SwitchAccounts we have to meet 2 conditions .merchantAccountCount > 1 and isRenderedFromIframe === false
     * @returns {boolean}
     */
    isRequiredToHandleSwitchAccounts() {
        let merchantInfo: MerchantInfo = this.localStorageService.merchantInfo;
        if (merchantInfo && Number(merchantInfo.merchantAccountCount) && Number(merchantInfo.merchantAccountCount) > 1 &&
            !isNullOrUndefined(merchantInfo.isRenderedFromIframe) && Boolean(merchantInfo.isRenderedFromIframe) === false) {
            return true;
        } else {
            return false;
        }
    }
}
