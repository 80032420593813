import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-trademark-notices',
  templateUrl: './trademark-notices.component.html'
})
export class TrademarkNoticesComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  open(event, content, optionSet?) {
    event.preventDefault();
    this.modalService.open(content, optionSet).result.then(
      result => {
      },
      reason => {
      }
    );
  }
}
