import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from "../../services/local-storage.service";
import { MerchantDetailsService } from "../../services/merchant-details.service";
import { isNullOrUndefined } from "util";
import { MerchantInfo } from "@models/MerchantDetails";
import { DashboardService } from "../../dashboard/dashboardService/dashboard.service";
import { ToastrService } from 'ngx-toastr';
import { Constants } from '@utils/constants';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BrowserSupportModal } from "../modals/browser-support/browser-support-modal.component";
import { UtilService } from "@services/util.service";
import { AiReportsService } from '@services/ai-reports.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: []
})
export class HeaderComponent implements OnInit {
    @HostBinding('class.site-header') true;
    public userName: string;
    public developerInfo: string;
    public isParcelProtection: boolean;
    public clickedSwitchAccount: string;
    public currentPageUrl = "";
    public setUpPending = true;
    public isDefaultMerchant: boolean;
    public isMultiCarrierEntitled: boolean;
    public devPortalUrl: string;
    public hasPostPayPaymentMethod: boolean;
    public hasMultipleAccounts = false;
    public isAiReportsEnabled = false;
    constructor(private router: Router, private activeRoute: ActivatedRoute, private localStorageService: LocalStorageService,
        private merchantDetailsService: MerchantDetailsService, private dashboardService: DashboardService, private toastr: ToastrService,
        protected modalService: NgbModal, private utilService: UtilService, private aiReportService: AiReportsService) {
    }

    ngOnInit() {
        this.getAndProcessUserDetails();
        this.showBrowserSupport();
        this.isRealTimeReportsLinkEnabled();
    }

    private isRealTimeReportsLinkEnabled() {
        this.aiReportService.isAiReportsEnabled().subscribe((data) => {
            this.isAiReportsEnabled = (data.isAiReportsEnabled === 'true')
        }, (err) => {
            this.isAiReportsEnabled = false;
        })
    }
    /**
     *  This method is used to get and process the Merchant details.
     */
    private getAndProcessUserDetails() {
        this.dashboardService.getUserDetails().subscribe((merchantInfoObj: MerchantInfo) => {
            this.userName = merchantInfoObj.email ? merchantInfoObj.email : null;
            this.developerInfo = merchantInfoObj.developerCompanyName && merchantInfoObj.developerCompanyName != ''
                ? merchantInfoObj.developerCompanyName : merchantInfoObj.developerName;
            this.isParcelProtection = merchantInfoObj.parcelProtection;
            let merchantInfoCarrierDetails = merchantInfoObj.carrierDetails;
            this.setUpPending = (merchantInfoCarrierDetails !== null && !merchantInfoCarrierDetails.isUSPSCarrierRegistered && !merchantInfoCarrierDetails.isUPSCarrierRegistered && !merchantInfoCarrierDetails.isFedExCarrierRegistered) ? true : false;
            this.currentPageUrl = this.router.url;
            this.isDefaultMerchant = merchantInfoObj.isDefaultMerchant;
            this.isMultiCarrierEntitled = (merchantInfoObj.integratorCarriers && merchantInfoObj.integratorCarriers.length > 0) ? true : false;
            this.hasPostPayPaymentMethod = merchantInfoObj.isPostPay;
            // Used to show the switch account link on left dropdown. Link would be visible only if the merchantAccountCount should be greater than 1.
            this.hasMultipleAccounts = this.merchantDetailsService.isRequiredToHandleSwitchAccounts();
            if (this.isDefaultMerchant) {
                this.utilService.getDevPortalUrl().subscribe((res) => {
                    this.devPortalUrl = res['url'];
                });
            }

        });
    }


    setUpWarning() {
        this.toastr.warning(Constants.SETUP_WARNING, null, { enableHtml: true });
    }

    /**
     *Used to show the support browser dialog for IE and Edge.
     */
    showBrowserSupport() {
        if (this.localStorageService.showBrowserSupport === null) {
            this.localStorageService.showBrowserSupport = 'true';
        }
        const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
        if (isIEOrEdge && this.localStorageService.showBrowserSupport === 'true') {
            const modalRef = this.modalService.open(BrowserSupportModal, { ariaLabelledBy: 'modal-two-buttons' })
            modalRef.result.then(
                result => {
                    this.localStorageService.showBrowserSupport = 'false';
                }
            );
        }
    }

    isMFAEnabled() {
        let mfaEnabled = false;
        if (this.localStorageService.merchantInfo && this.localStorageService.merchantInfo.enableMFA) {
            mfaEnabled = true;
        }
        return mfaEnabled;
    }

}
