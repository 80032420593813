<div class="modal fade" id="achTimeTableModal" tabindex="-1" role="dialog" aria-labelledby="achTimeTableModalLabel"
  aria-hidden="true" style="z-index: 999999">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="" id="achFundTransfersLabel">In most scenarios, you should get the funds immediately for any request
          between Monday to Sunday at any time of the day. But, in certain scenarios with large fund requests, it could take longer to transfer money in the case of ACH.<br><br>Refer to
          this table for exact ACH fund transfer details in those exception scenarios based on when the request is
          initiated.</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="ach-time-table-close">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <table id="classTable" class="table table-bordered text-center">
          <thead>
          </thead>
          <tbody>
            <tr style="background-color: #F4F5FA">
              <td colspan="2" class="px-1 py-3"><b>Funds Requested by&#42;</b></td>
              <td class="px-1 py-3"><b>Mon</b> 7:30PM ET</td>
              <td class="px-1 py-3"><b>Tue</b> 7:30PM ET</td>
              <td class="px-1 py-3"><b>Wed</b> 7:30PM ET</td>
              <td class="px-1 py-3"><b>Thu</b> 7:30PM ET</td>
              <td class="px-1 py-3"><b>Fri</b> 7:30PM ET</td>
              <td class="px-1 py-3"><b>Sat/Sun</b> 7:30PM ET</td>
            </tr>
            <tr style="background-color: #FFCCCC">
              <td colspan="2" class="px-1 py-3"><b>Latest Availability of Funds</b></td>
              <td class="px-1 py-3"><b>Thu</b> 10:30PM ET</td>
              <td class="px-1 py-3"><b>Fri</b> 10:30PM ET</td>
              <td class="px-1 py-3"><b>Mon</b> 10:30PM ET</td>
              <td class="px-1 py-3"><b>Tue</b> 10:30PM ET</td>
              <td class="px-1 py-3"><b>Wed</b> 10:30PM ET</td>
              <td class="px-1 py-3"><b>Thu</b> 10:30PM ET</td>
            </tr>
        </tbody>
        </table>
        <p class="pl-3" id="ach-fund-info">&#42; Funds requested on or after the cut-off time will be processed on the
          next day.</p>
      </div>
    </div>
  </div>
</div>